<template>
  <div>
    <div v-if="showPointInfo" class="show-class-info">
      <PointInfo @my-click="closePointInfo" />
    </div>
    <div>
      <div v-if="point_info_list.length > 0">
        <!-- CardInstance -->
        <div
          class="change-div"
          v-for="content in point_info_list"
          :key="content"
        >
          <!-- <router-link to="/PointInfo"> -->
          <table
            @click="toPointInfo(content.uid, content.guidance_base.location)"
          >
            <tr>
              <th>
                <!-- <img :src="image_url" /> -->
                <div
                  class="image_circle"
                  :style="{
                    backgroundImage: 'url(' + content.icon + ')',
                  }"
                ></div>
              </th>
              <!-- 各カード Listインスタンス -->
              <td @click="openPointInfo(1)">
                <div class="card-title">
                  <h4>
                    {{ content.guidance_base.location }}
                  </h4>
                </div>
                <div class="card-content">
                  <h5>
                    {{ content.certified_coach }}
                    <p></p>
                    {{ content.remarks }}
                  </h5>
                </div>
              </td>
            </tr>
          </table>

          <!-- </router-link> -->
        </div>
      </div>
      <div>
        <div class="back-map" @click="doBackMap()">
          <h4 class="back-map-font">マップで表示</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat";
import PointInfo from "@/components/class/modal/point_info_pad";
export default {
  name: "ClassList",
  props: {
    searchPoint: Array,
  },
  components: {
    PointInfo,
  },
  data() {
    return {
      pontDetail: false,
      point_info_list: [],
      image_url:
        "https://www.nobuta-nakameguro.net/wp-content/uploads/2020/06/jason-rosewell-ASKeuOZqhYU-unsplash-scaled.jpg",
      search_Point_cnt: 0,
      showPointInfo: false,
    };
  },
  watch: {
    searchPoint: {
      async handler(val) {
        if (val.length > 0) {
          if (this.search_Point_cnt >= 0) {
            await this.searchRes(
              val[this.search_Point_cnt],
              this.search_Point_cnt
            );
          }
          this.search_Point_cnt = this.search_Point_cnt + 1;
        } else {
          this.search_Point_cnt = 0;
          this.point_info_list = [];
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getPointInfoList();
  },
  methods: {
    async searchRes(val) {
      let self = this;
      self.click_index = 0;
      self.point_info_list.push(val);
    },
    toPointInfo(uid, location) {
      //拠点UIDはlocalStorgeに保存：userのUID
      this.glb_set_localStorage("point_uid", uid);
      this.glb_set_localStorage("point_location", location);
      this.showPointInfo = true;
    },
    closePointInfo() {
      this.showPointInfo = false;
    },
    async getPointInfoList() {
      let self = this;
      await firebase
        .firestore()
        .collection(self.glb_table.users)
        .get()
        .then(async function(querySnapshot) {
          let cnt = 0;
          await querySnapshot.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection(self.glb_table.users)
              .doc(doc.id)
              .get()
              .then(async (snap) => {
                if (snap.data().guidance_base != null) {
                  for (var i in snap.data().guidance_base) {
                    if (snap.data().guidance_base[i].location != null) {
                      let locationData = snap.data().guidance_base[i];
                      let data = snap.data();
                      if (snap.data().uid == null || snap.data().uid == "") {
                        data.uid = doc.id;
                      }
                      data.guidance_base = locationData;
                      self.point_info_list.push(data);
                      cnt = cnt + 1;
                    }
                  }
                }
                if (cnt == querySnapshot.forEach.length) {
                  self.point_info_list = await self.glb_set_random_for_list(
                    self.point_info_list
                  );
                }
              });
          });
        });
    },
    doBackMap() {
      this.$emit("do-back-map", true);
    },
    // 拠点詳細モーダル開示 別ページでテスト
    openPointInfo() {
      // if (value == 1) {
      //   this.pontDetail = true;
      // } else {
      //   this.pontDetail = false;
      // }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.change-div {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 40%;
  height: 120px;
  background: rgb(255, 255, 255);
  /* background-color: black; */
  border-radius: 15px;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}
.change-div table {
  width: 100%;
  height: 100%;
}
.change-div table th {
  width: 120px;
  /* background-color: #fff; */
}
.change-div table td {
  position: relative;
}

.change-div table td .card-title {
  position: absolute;
  height: 40px;
  top: 12px;
  left: 0px;
  text-align: left;
}
.change-div table td .card-content {
  position: absolute;
  top: 80px;
  left: 0px;
  height: 40px;
  text-align: left;
  overflow: auto;
}

.image_circle {
  text-align: center;
  height: 100px;
  width: 100px;
  margin: 5px;
  border-radius: 10%;
  background-position: 54% 36%;
  background-size: cover;
}
.back-map {
  position: fixed;
  z-index: 3;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(80, 80, 80, 0.9);
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}
.back-map-font {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 12px;
}
.show-class-info {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}
</style>
