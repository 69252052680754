<template>
  <div>
    <div class="comfirmEllent" v-show="confirmModal">
      <ConfirmModal />
    </div>
    <div class="about" v-if="ClassHeader">
      <ClassHeader
        :page="4"
        @my-click="closeClassInfo"
        @copy-click="copyLink"
      />
    </div>
    <div class="body-page" v-show="!confirmModal">
      <div v-if="class_info != null">
        <img
          :src="class_info.class_icon.image_url"
          id="class-img"
          class="img-fluid"
          alt="Responsive image"
        />
        <div class="class-info-card">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th scope="col" class="th-title">レッスン</th>

                <td class="detail">{{ class_info.lesson_name }}</td>
              </tr>
              <tr>
                <th scope="col" class="th-title">クラス名</th>
                <td class="detail">{{ class_info.class_name }}</td>
              </tr>
              <tr>
                <th scope="col" class="th-title">コーチ名</th>
                <td class="detail" @click="openCoachProfile()">
                  {{ class_info.coach_name }}
                </td>
              </tr>
              <!-- 定期開催 -->
              <tr v-if="class_info.organize_type === '定期'" class="orgTime">
                <th scope="col" class="th-title">
                  日時(定期)
                </th>
                <td class="detail">
                  <div
                    v-for="time in class_info.fixed_term_organize"
                    :key="time.id"
                    class="orgTime"
                  >
                    <div
                      v-if="time.start_time !== '' || time.end_time !== ''"
                      class="date-font"
                    >
                      {{ time.date }}曜日：{{ time.start_time }}~{{
                        time.end_time
                      }}
                      <br />
                    </div>
                  </div>
                </td>
              </tr>
              <!-- 不定期開催 -->
              <tr v-else>
                <th scope="col" class="th-title">
                  日時(不定期)
                </th>
                <td class="detail">
                  <div
                    v-for="time in class_info.irregular_organize"
                    :key="time.id"
                  >
                    {{ toTime(time.start_time) }}~{{ justTime(time.end_time) }}
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">場所</th>
                <td class="detail">
                  {{ class_info.location }}
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">住所</th>
                <td class="detail">
                  〒{{ class_info.location_zip }}
                  <br />
                  {{ class_info.location_address }}
                </td>
              </tr>
              <tr v-if="class_info.url !== '' && class_info.url !== null">
                <th scope="col" class="th-title">URL</th>
                <td class="detail">
                  <div @click="openDetail()" id="detailLink">
                    詳細
                  </div>
                </td>
              </tr>
              <tr v-if="class_info.pdf !== '' && class_info.pdf !== null">
                <th scope="col" class="th-title">PDF資料</th>
                <td class="detail">
                  <div @click="openPDFDetail()" id="detailLink">
                    資料
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">料金</th>
                <td class="detail">
                  ￥
                  {{ class_info.price }}
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">申込方法</th>
                <!-- 配列要素 から文字列に変更された為 修正対象 12/16 OgawaK-->
                <td class="detail">
                  <div class="font-p">
                    {{ class_info.application_process }}
                    <br />
                    <a
                      class="application_url"
                      :href="class_info.application_url"
                      target="_blank"
                      rel="noopener noreferrer"
                      v-show="class_info.application_url != ''"
                      >申込サイトへ</a
                    >
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">お問合せ先</th>
                <td class="detail">
                  <div class="font-p">
                    {{ class_info.contact }}
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">備考</th>
                <td class="detail">
                  <div class="font-p">
                    {{ class_info.remarks }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <ImageListView :image_view_list="class_info.profile_image_list" />
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassHeader from "@/components/class/class_header";
import moment from "moment";
import ConfirmModal from "@/components/class/modal/class_Enttry_comfirm";
import ImageListView from "@/components/tool/ImageListView";
import firebase from "firebase/compat";
export default {
  // 前画面から引き継ぎData
  props: [],

  name: "ClassRegister",
  components: {
    ClassHeader,
    ConfirmModal,
    ImageListView,
    // Datepicker,
  },
  data() {
    return {
      image_src: null,
      memoList: [],
      body: true,
      ClassHeader: true,
      confirmModal: false,
      date: new Date(),
      add_image_url:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/user_upload%2Fimage-add.svg?alt=media&token=7006d278-166f-4675-8e09-81fdd569ca1d",
      dateSelect: "定期",
      imageList: [],
      deleteImage: false,
      class_info: null,
      // default 不定期開催
      Regular: false,
      urlLink: "",
    };
  },
  async created() {
    this.getDateTime();
    //クラスIDでDB検索 (UID)表示するデータ取得
    await this.getClassInfo();
    await this.Regularjudge();
  },
  methods: {
    onCopySuccess(e) {
      alert(e.text + " のコピーに成功しました");
    },
    onCopyError(e) {
      alert(e.text + " のコピーに失敗しました");
    },
    openCoachProfile() {},
    // 定期、不定期 判定 Regular判定条件
    Regularjudge() {
      if (this.class_info.organize_type == "不定期") {
        this.Regular = false;
      } else {
        this.Regular = true;
      }
    },
    async getClassInfo() {
      // DBからクラス情報取得 LocalStorage使用しなくなる可能性あるため、再度DBから取得
      let self = this;
      let classID = localStorage.getItem("classID");
      let url = new URL(window.location.href);
      if (url.hostname == "localhost") {
        this.urlLink =
          "http://" + url.hostname + ":" + url.port + "/?id=" + classID + "#/";
      } else {
        this.urlLink = "https://" + url.hostname + "/?id=" + classID + "#/";
      }
      await firebase
        .firestore()
        .collection(self.glb_table.class)
        //クラスID
        .doc(classID)
        .get()
        .then((snap) => {
          self.class_info = snap.data();
        });
      self.glb_set_localStorage("classInfo", JSON.stringify(self.class_info));
    },

    // 時間フォーマット FullData
    toTime(timeValue) {
      let TIME = this.toDateTime(timeValue);
      return TIME;
    },
    // 時間フォーマット ShortTime  [19:00]
    justTime(timeValue) {
      let TIME = this.toJustTime(timeValue);
      return TIME;
    },
    openconfirm() {
      this.confirmModal = true;

      this.ClassHeader = false;
    },
    openPDFDetail() {
      const self = this;
      self.$router.push(window.open(self.class_info.pdf, "_blank"));
    },
    // 開催クラス詳細リンク newTabでopen
    openDetail() {
      const self = this;
      self.$router.push(window.open(self.class_info.url, "_blank"));
    },

    getDate(value, index) {
      this.class_info.irregular_organize[index].date = value;
    },
    getDateTime() {
      this.start_date_h_option = this.glb_get_time_h();
      this.start_date_m_option = this.glb_get_time_m();
    },
    plusDate(index) {
      let datalist = {
        date: moment(new Date()).format("YYYY/MM/DD"),
        start_time: "12:00",
        end_time: "",
      };
      this.class_info.irregular_organize.splice(index + 1, 0, datalist);
    },
    deleteDate(index) {
      this.class_info.irregular_organize.splice(index, 1);
    },
    async getAddress() {
      this.class_info.location_address = await this.glb_get_address(
        this.class_info.location_zip
      );
    },
    profile_image_list_up(event) {
      let self = this;
      let selectPic = event.target.files[0];
      var pos = selectPic.name.split(".").pop();
      let imageCheck = this.glb_validete_img(pos);
      if (imageCheck) {
        self.imageList.push(event.target.files[0]);
        var reader = new FileReader();
        reader.onload = function(event) {
          self.class_info.profile_image_list.push({
            image_url: event.target.result,
            image_name: "",
          });
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    closeClassInfo() {
      this.$emit("my-click", true);
    },
    async copyLink() {
      this.$copyText(this.urlLink).then(
        function() {
          alert("URLをコピーしました！");
        },
        function() {
          alert("URLのコピーが失敗しました！");
        }
      );
    },
  },
};
</script>
<style scoped>
.body-page {
  /* position: absolute;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin: 0%;
  margin-top: 57px;
  -webkit-overflow-scrolling: auto;
 */

  position: absolute;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin: 0%;
  margin-top: 57px;
  min-height: 1200px;
  overflow: auto;
}
#class-img {
  margin-top: 10px;
  width: 100%;
  min-height: 250px;
  height: auto;
}
.th-title {
  width: 35%;
  font-size: 14px;
  background-color: rgb(214, 214, 214);
  border-top: none;
}

.detail {
  text-align: start;
  padding-left: 3%;
  font-size: 14px;
  border-top: none;
}
#detailLink {
  border-bottom: solid;
  border-color: #868686;
  width: 28px;
  color: rgb(0, 0, 0);
}

#entry-btn {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 30%;
  padding: 0%;
  color: #ffffff;
  background-color: rgb(3, 163, 168);
  text-align: center;
  height: 35px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  box-shadow: 3px 6px 9px rgb(139 139 139);
}

.orgTime {
  font-size: 1px;
}
.table {
  font-size: 1.7rem;
  width: 100%;
  border-radius: 10px;
  border: none;
  padding-top: 12px;
  margin: 0% auto 0% auto;
}
.table td .font-p {
  white-space: pre-wrap;
}
.date-font {
  font-size: 14px;
}
.application_url {
  color: black;
  border: #868686;
  /* background-color: rgb(245, 239, 214); */
  font-size: 10pt;
  text-decoration: underline;
  text-decoration-color: #4e4d4d;
  /* font-weight: bold; */
}
</style>
