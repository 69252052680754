<template>
  <div class="map-div">
    <div>
      <nav class="navbar navbar-light bg-light nav-div" v-show="!searcgModal">
        <div class="logo-serch-flex">
          <img
            class="logo-div"
            src="@/assets/logo/logo.svg"
            @click="doHomepage()"
          />
          <div class="search">
            <button
              type="button"
              class="btn btn-light search-button"
              @click="openModal()"
            >
              クラスを検索
            </button>
          </div>
        </div>
        <div class="tab-wrap">
          <div v-if="mapTab == 0">
            <input
              id="TAB-01"
              type="radio"
              name="TAB"
              class="tab-switch"
              checked="checked"
            />
            <label class="tab-label" for="TAB-01" @click="changeTab(0)"
              >クラス</label
            >
            <input id="TAB-03" type="radio" name="TAB" class="tab-switch" />
            <label class="tab-label" for="TAB-03" @click="changeTab(1)"
              >認定コーチ</label
            >
          </div>

          <div v-else>
            <input id="TAB-01" type="radio" name="TAB" class="tab-switch" />
            <label class="tab-label" for="TAB-01" @click="changeTab(0)"
              >クラス</label
            >
            <input
              id="TAB-03"
              type="radio"
              name="TAB"
              class="tab-switch"
              checked="checked"
            />
            <label class="tab-label" for="TAB-03" @click="changeTab(1)"
              >認定コーチ</label
            >
          </div>
        </div>
        <div v-show="user_auth" class="classRegister-div">
          <!-- <button class="classRegister">申請</button> -->
          <a
            class="link_button"
            href="https://trainer.fitnesscamp.jp/#/classRegister"
          >
            クラス申請
          </a>
        </div>
      </nav>
    </div>
    <div v-show="classMap">
      <div v-show="listModal" class="list-modal-page">
        <ClassList :searchClass="class_info_list" @do-back-map="doBackMap" />
      </div>
    </div>

    <div v-show="pointMap">
      <div v-show="listModal" class="list-modal-page">
        <PointList :searchPoint="point_info_list" @do-back-map="doBackMap" />
      </div>
    </div>
    <div id="overlay" v-show="searcgModal" class="search-modal-page">
      <ClassSearch @closeModal="closeModal" @searcModal="searcModal" />
    </div>

    <div v-show="pointMap">
      <PointMap :searchPoint="point_info_list" :p_google="google_config" />
    </div>
    <div v-show="classMap">
      <ClassMap :searchClass="class_info_list" :google="google_config" />
    </div>

    <div v-show="classMap">
      <div class="class-list-div" @click="openList(0)">
        <br />
        <h4>
          クラスリスト一覧
        </h4>
      </div>
    </div>

    <div v-show="pointMap">
      <div class="class-list-div" @click="openList(1)">
        <br />
        <h4>
          認定コーチ一覧
        </h4>
      </div>
    </div>

     <div v-if="user_auth" class="navigation-style">
      <Navigation :page="3" />
    </div>
  </div>
</template>

<script>
import ClassList from "../../components/class/class_list_pad";
import PointList from "../../components/class/point_list_pad";
import ClassMap from "../../components/class/class_map_pad";
import PointMap from "../../components/class/point_map_pad.vue";
import ClassSearch from "../../components/class/modal/class_search_pad.vue";
import firebase from "firebase/compat";
import GoogleMapsApiLoader from "google-maps-api-loader";
import Navigation from "@/components/Navigation";

export default {
  name: "Map",
  components: {
    // 各List
    ClassList,
    PointList,
    // クラス、拠点 地図
    ClassMap,
    PointMap,
    // 検索画面
    ClassSearch,
     //ツールバー
    Navigation,
  },
  data() {
    return {
      listModal: false,
      searcgModal: false,
      mapTab: null,
      classMap: false,
      pointMap: false,
      user_auth: false,
      class_info_list: [],
      point_info_list: [],
      google_config: null,
    };
  },
  async created() {
    await this.set_google_config();
    await this.getUserAuth();
  },
  methods: {
    async set_google_config() {
      this.google_config = await new GoogleMapsApiLoader({
        apiKey: "AIzaSyAAi_xslHLasOUhkzGZD-8QFgtYkLYvaFk",
      });
    },
    doHomepage() {
      window.location.href = "https://fitnesscamp.jp/";
    },
    async getUserAuth() {
      var result = location.search.indexOf("trainer=true");
      if (result > 0) {
        this.user_auth = true;
      }
      var result_point = location.search.indexOf("point=true");
      if (result_point > 0) {
        this.mapTab = 1;
        this.pointMap = !this.pointMap;
      } else {
        this.mapTab = 0;
        this.classMap = !this.classMap;
      }
    },
    openModal: function() {
      this.searcgModal = true;
    },
    // クラス、拠点 tab切り替え
    changeTab(val) {
      this.mapTab = val;
      if (this.mapTab == 0) {
        this.pointMap = false;
        this.classMap = true;
      } else {
        this.pointMap = true;
        this.classMap = false;
      }
    },
    // モーダルDel
    closeModal() {
      location.reload();
      this.searcgModal = false;
    },
    async searchClass(value) {
      let self = this;
      await firebase
        .firestore()
        .collection(self.glb_table.class)
        .get()
        .then(async function(querySnapshot) {
          await querySnapshot.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection(self.glb_table.class)
              .doc(doc.id)
              .get()
              .then(async (snap) => {
                //各クラスの情報を取得する
                if (
                  snap.data().status == "公開" &&
                  snap.data().inspection == self.glb_inspection.examined
                ) {
                  if (
                    snap.data().lat_and_lng.lat != null &&
                    snap.data().lat_and_lng.lat != "" &&
                    snap.data().lat_and_lng.lng != null &&
                    snap.data().lat_and_lng.lng != ""
                  ) {
                    var resBool = await self.glb_search(value, snap.data());
                    if (resBool) {
                      let data = snap.data();
                      data.class_uid = doc.id;
                      self.class_info_list.push(data);
                    }
                  }
                }
              });
          });
        });
    },
    async searchPoint(value) {
      let self = this;
      await firebase
        .firestore()
        .collection(self.glb_table.users)
        .get()
        .then(async function(querySnapshot) {
          await querySnapshot.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection(self.glb_table.users)
              .doc(doc.id)
              .get()
              .then(async (snap) => {
                //各クラスの情報を取得する
                let searchTarget = false;
                //検索：01:キーワード
                if (value.keyWord != null && value.keyWord != "") {
                  //指導拠点文字列に変更
                  var guidance_base_str = "";
                  if (snap.data().guidance_base != null) {
                    for (var i = 0; i < snap.data().guidance_base.length; i++) {
                      if (
                        snap.data().guidance_base[i].location != null &&
                        snap.data().guidance_base[i].location != ""
                      ) {
                        guidance_base_str =
                          guidance_base_str +
                          snap.data().guidance_base[i].location;
                        //資格文字列に変更
                        var qualification_str = "";
                        for (var j = 0; j < snap.data().qualification; j++) {
                          qualification_str =
                            qualification_str +
                            snap.data().guidance_base[j].qualification;
                        }
                        //検索キーワードがあり
                        var searchData = "";
                        if (
                          await self.glb_check_null(snap.data().certified_coach)
                        ) {
                          searchData = searchData + snap.data().certified_coach;
                        }
                        if (await self.glb_check_null(snap.data().club)) {
                          searchData = searchData + snap.data().club;
                        }
                        if (await self.glb_check_null(snap.data().coach_name)) {
                          searchData = searchData + snap.data().coach_name;
                        }
                        if (
                          await self.glb_check_null(
                            snap.data().guidance_base_str
                          )
                        ) {
                          searchData =
                            searchData + snap.data().guidance_base_str;
                        }
                        if (
                          await self.glb_check_null(
                            snap.data().qualification_str
                          )
                        ) {
                          searchData =
                            searchData + snap.data().qualification_str;
                        }
                        if (await self.glb_check_null(snap.data().self_pr)) {
                          searchData = searchData + snap.data().self_pr;
                        }
                        if (await self.glb_check_null(snap.data().remarks)) {
                          searchData = searchData + snap.data().remarks;
                        }
                        var resultIndex = searchData.indexOf(value.keyWord);
                        if (resultIndex >= 0) {
                          searchTarget = true;
                        }
                        if (searchTarget) {
                          let data = snap.data();
                          data.uid = doc.id;
                          data.guidance_base = snap.data().guidance_base[i];
                          self.point_info_list.push(data);
                        }
                      }
                    }
                  }
                }
              });
          });
        });
    },
    async searcModal(value) {
      let self = this;
      this.searcgModal = false;
      if (this.mapTab == 0) {
        self.class_info_list = [];
        self.searchClass(value);
      }
      if (this.mapTab == 1) {
        //認定コーチ検索
        self.point_info_list = [];
        self.searchPoint(value);
      }
    },

    doSearch() {
      // 検索実行処理
    },
    openList() {
      this.listModal = true;
    },
    doBackMap(value) {
      if (value) {
        this.listModal = !value;
      }
    },
  },
};
</script>

<style scoped>
.map-div {
  overflow: auto;
  height: auto;
  width: 100%;
  margin: auto;
}
.nav-div {
  width: 100%;
  height: 120px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.search {
  width: 300px;
  margin-top: 10px;
  border: none;
  outline: #fff;
}
.search-button {
  margin-left: auto;
  margin-right: auto;
  height: 46px;
  width: 95%;
  border-radius: 20px;
  font-size: 14px;
  outline: #fff;
  border: none !important;
  color: #939393;
  text-align: left;
  padding-left: 20px;
  background: url(../../assets/class/search.png) no-repeat 92% 12px;
  background-size: 22px;
  background-color: #eeeeee;
}
.search-button :active {
  color: #939393;
}

.map {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 90vh;
}
.tab-wrap {
  margin-top: 5px;
  flex-wrap: wrap;
  /* overflow: hidden; */
  padding: 0 0 20px;
}

.tab-label {
  color: Gray;
  cursor: pointer;
  flex: -1;
  font-weight: bold;
  order: 1;
  padding: 12px 24px;
  position: relative;
  text-align: center;
  transition: cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  user-select: none;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
}

.tab-label:hover {
  background: rgba(255, 255, 255, 0.1);
}

.tab-switch:checked + .tab-label {
  color: #6b6b6b;
}

.tab-label::after {
  background: #6b6b6b;
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: translateX(100%);
  transition: cubic-bezier(0.4, 0, 0.2, 1) 0.2s 80ms;
  width: 100%;
  z-index: 1;
}

.tab-switch:checked ~ .tab-label::after {
  transform: translateX(-100%);
}

.tab-switch:checked + .tab-label::after {
  opacity: 1;
  transform: translateX(0);
}

.tab-content {
  height: 0;
  opacity: 0;
  padding: 0 20px;
  pointer-events: none;
  transform: translateX(-30%);
  transition: transform 0.3s 80ms, opacity 0.3s 80ms;
  width: 100%;
}

.tab-switch:checked ~ .tab-content {
  transform: translateX(30%);
}

.tab-switch:checked + .tab-label + .tab-content {
  height: auto;
  opacity: 1;
  order: 1;
  pointer-events: auto;
  transform: translateX(0);
}

.tab-wrap::after {
  content: "";
  height: 20px;
  order: -1;
  width: 100%;
}

.tab-switch {
  display: none;
}
.class-list-div {
  position: relative;
  top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 130px;
  background-color: #ffffff;
  border-radius: 8% 8% 0% 0%;
  color: rgb(0, 0, 0);
}
.list-modal-page {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100vh;
  animation: fadeIn 0.5s ease 0.1s 1 normal backwards;
  background-color: #ffffff;
}
.search-modal-page {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100vh;
  animation: fadeIn 0.5s ease 0.1s 1 normal backwards;
  background-color: #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(600px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.logo-serch-flex {
  display: flex;
  width: 100%;

  /* background-color: #6b6b6b; */
}
.logo-div {
  margin-top: 8px;
  width: 50px;
  height: 50px;
}
.classRegister-div {
  margin-top: -20px;
  /* margin-right: 12px; */
  /* background-color: #6b6b6b; */
}
/*以下class名link_buttonのデザイン*/
.link_button {
  font-weight: bold; /*太字に*/
  text-decoration: none; /*下線消す*/
  background: #eeeeee;
  color: rgb(165, 165, 165); /*文字を白に*/
  padding: 5px 10px; /*内側の余白*/
  border-radius: 5px; /*角を丸くする*/
  font-family: "Avenir", "Arial"; /*フォントをいい感じに*/
}
/*以下カーソルを当てたとき*/
.link_button:hover {
  background: silver; /*背景色をシルバーに*/
  text-decoration: none; /*下線を消す*/
}

/*以下訪問済みのデザイン*/
.link_button:visited {
  color: gray; /*文字をグレイに*/
}
</style>
