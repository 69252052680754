export default {
  data() {
    return {
      glb_inspection: {
        under_review: "未審査",
        examined: "審査済",
        rejected: "不許可"
      },
      glb_table: {
        class: "class",//クラステーブル
        users: "users",//ユーザーテーブル
        class_applicant: "class_applicant",//申請者テーブル
        mail: "mail",//メールテーブル
        program: "program",//プログラム
        qualification: "qualification",
        google_map: "google_map"
      },
      glb_marker: {
        red: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
        blue: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
        green: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
        yello: "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
        gray: "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=|6699cc|"
        // gray: "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/test%2Flocation-pin.png?alt=media&token=f50ca80d-b949-44d8-b7ba-4f010f3aaf8b",
      },
      glb_hold_status: {
        held: "開催済",
        not_held: "未開催"
      }
    };
  },
  async mounted() {
    // self.glb_table.qualification
  },
  computed: {

  },
  methods: {

  },
};
