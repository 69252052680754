<template>
  <div>
    <!-- 画像openModal -->
    <div v-show="imgModal">
      <div class="open-img-modal">
        <div class="open-image-div">
          <img class="open-image" :src="openImageUrl" />
        </div>
        <div class="d-flex justify-content-around">
          <img
            class="close-image"
            src="@/assets/profile/back.svg"
            @click="imageEvent(0)"
          />
          <img
            class="close-image"
            src="@/assets/profile/close.svg"
            @click="imageEvent(1)"
          />
          <img
            class="close-image"
            src="@/assets/profile/next.svg"
            @click="imageEvent(2)"
          />
        </div>
      </div>
    </div>
    <div v-if="program_event">
      <program />
    </div>
    <div v-else>
      <div class="profile">
        <div class="profile-div">
          <div class="profile-card">
            <!-- <a href="#/profile/mypage"> -->
            <div class="icon-style">
              <div
                v-if="userInfo.icon"
                class="image_circle"
                :style="{ backgroundImage: 'url(' + userInfo.icon + ')' }"
              ></div>
              <div
                v-else
                class="image_circle"
                :style="{ backgroundImage: 'url()' }"
              ></div>
            </div>
            <div>
              <br />
              <h4
                class="display-6 ninkname-text"
                v-if="userInfo.certified_coach"
              >
                {{ userInfo.certified_coach }}
              </h4>
              <h4 class="display-6 ninkname-text" v-else>認定コーチ名未登録</h4>
            </div>

            <div class="profile-card-table">
              <table>
                <!-- <tr>
                  <th>
                    <h4 class="display-5 title-style">お名前</h4>
                  </th>
                  <td>
                    <p class="h4 conten-style">
                      {{ userInfo.last_name }} {{ userInfo.first_name }}
                    </p>
                  </td>
                </tr> -->
                <!-- <tr>
                  <th>
                    <h4 class="display-5 title-style">メール</h4>
                  </th>
                  <td>
                    <p class="h4 conten-style">{{ userInfo.mail }}</p>
                  </td>
                </tr> -->
                <tr>
                  <th>
                    <h4 class="display-5 title-style">Fitness Camp指導拠点</h4>
                  </th>
                  <td>
                    <div class="display-info-1">
                      <div
                        class="qualification-div"
                        v-for="content in userInfo.guidance_base"
                        v-bind:key="content"
                      >
                        <a>{{ content.location }}</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <h4 class="display-5 title-style">その他保有資格</h4>
                  </th>
                  <td>
                    <div class="display-info-1">
                      <div
                        class="qualification-div"
                        v-for="content in userInfo.qualification"
                        v-bind:key="content"
                      >
                        <a>{{ content }}</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <h4 class="display-5 title-style">自己PR</h4>
                  </th>
                  <td>
                    <p class="h5 conten-style">{{ userInfo.self_pr }}</p>
                  </td>
                </tr>
              </table>
            </div>
            <div class="profile-card-table">
              <table>
                <tr>
                  <th>
                    <h4 class="display-5 title-style">プログラム</h4>
                  </th>

                  <td class="d-flex justify-content-start">
                    <div
                      v-for="content in programList"
                      v-bind:key="content"
                      class="d-flex justify-content-start"
                    >
                      <div
                        class="image-padding"
                        @click="openProgram(content.uid, content.name)"
                      >
                        <img
                          class="program-icon"
                          :src="content.home_image.image_url"
                        />
                        <h4 class="display-5 program-font-style">
                          {{ content.name }}
                        </h4>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <!-- </a> -->
            <!-- SNS項目 -->
            <div class="profile-card-table">
              <table>
                <tr>
                  <th>
                    <h4 class="display-5 title-style">SNS</h4>
                  </th>
                  <td>
                    <div class="display-info-1">
                      <div v-if="userInfo.sns">
                        <img
                          v-if="userInfo.sns.facebook"
                          src="@/assets/profile/FACEBOOK.svg"
                          class="icon-div"
                          @click="doSns(1)"
                        />
                        <img
                          v-if="userInfo.sns.instagram"
                          src="@/assets/profile/インスタグラム.svg"
                          class="icon-div"
                          @click="doSns(2)"
                        />
                        <img
                          v-if="userInfo.sns.mypage"
                          src="@/assets/profile/link.svg"
                          class="icon-div"
                          @click="doSns(3)"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <div class="text-center">
              <div class="img-div">
                <div class="d-flex justify-content-center img-fiex">
                  <div
                    v-for="(content, index) in userInfo.profile_image_list"
                    v-bind:key="content.id"
                  >
                    <!-- 活動画像 -->
                    <div
                      class="image-list"
                      :style="{
                        backgroundImage: 'url(' + content.image_url + ')',
                      }"
                      @click="openImage(index)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// スクリプト
import firebase from "firebase/compat";
import program from "@/components/Program";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
export default {
  name: "pointInfoProfile",
  // props: {
  //   userUid: String,
  // },
  components: {
    program,
  },
  props: {
    close_program: null,
  },
  watch: {
    close_program: function(newVal) {
      if (newVal == false) {
        this.program_event = false;
      }
    },
  },
  data() {
    return {
      userInfo: {},
      modal: false,
      imgModal: false,
      openImageUrl: "",
      event: 0,
      programList: [],
      program_event: false,
    };
  },
  created: async function() {
    await this.getUserInfo();
    await this.getProgram();
  },
  methods: {
    openImage(index) {
      this.imgModal = true;
      this.openImageUrl = this.userInfo.profile_image_list[index].image_url;
      this.image_index = index;
      const imgModal = document.querySelector(".open-img-modal");
      disableBodyScroll(imgModal);
    },
    // 画像Modal(下部btn)
    imageEvent(value) {
      switch (value) {
        case 0:
          if (this.image_index > 0) {
            this.openImageUrl = this.userInfo.profile_image_list[
              this.image_index - 1
            ].image_url;
            this.image_index = this.image_index - 1;
          }
          break;
        case 1: {
          clearAllBodyScrollLocks();
          this.imgModal = false;
          this.openImageUrl = "";
          break;
        }
        case 2: {
          if (this.image_index < this.userInfo.profile_image_list.length - 1) {
            this.openImageUrl = this.userInfo.profile_image_list[
              this.image_index + 1
            ].image_url;
            this.image_index = this.image_index + 1;
          }
          break;
        }
      }
    },
    doSns(value) {
      switch (value) {
        case 1:
          //facebook
          window.open(this.userInfo.sns.facebook, "_blank");
          break;
        case 2:
          //instagram
          window.open(this.userInfo.sns.instagram, "_blank");
          break;
        case 3:
          //mypage
          window.open(this.userInfo.sns.mypage, "_blank");
          break;
      }
    },
    async getProgram() {
      let self = this;
      var userUid = await this.glb_get_localStorage("point_uid");
      firebase
        .firestore()
        .collection(self.glb_table.program)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            firebase
              .firestore()
              .collection(self.glb_table.program)
              .doc(doc.id)
              .get()
              .then(async (snap) => {
                if (
                  snap.data().trainer == userUid &&
                  snap.data().status == "公開"
                ) {
                  self.programList.push({
                    uid: doc.id,
                    name: snap.data().name,
                    home_image: snap.data().home_image,
                  });
                }
              });
          });
        });
    },
    openProgram(programUid, programName) {
      localStorage.setItem("program_uid", programUid);
      this.program_event = true;
      let data = {
        name: programName,
        event: true,
      };
      this.$emit("program-click", data);
    },
    async getUserInfo() {
      var userUid = await this.glb_get_localStorage("point_uid");
      let self = this;
      await firebase
        .firestore()
        .collection(self.glb_table.users)
        .doc(userUid)
        .get()
        .then((snap) => {
          self.userInfo = snap.data();
        });
    },
  },
};
</script>

<style scoped>
.profile {
  /* position: absolute; */
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  margin: 0%;
  margin-top: 45px;
  min-height: 700px;
}
.profile-card {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  height: auto;
  border-radius: 10px;
  background: #fafafa;
  box-shadow: 9px 9px 9px rgba(0, 0, 0, 0.16);
}
.profile-card .icon-style {
  padding-top: 2%;
}

.image_circle {
  margin-left: auto;
  margin-right: auto;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-position: 54% 36%;
  background-size: cover;
  border: 2px solid rgba(236, 236, 236, 0.5);
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}

.title-style {
  font-weight: 600;
  margin-bottom: 0%;
  float: left;
  padding-left: 12px;
  color: rgb(141, 141, 141);
}

.conten-style {
  font-weight: 600;
  float: left;
  color: rgb(66, 66, 66);
}
.ninkname-text {
  font-weight: 600;
  margin-bottom: 0%;
  padding-left: 2%;
  padding-bottom: 2%;
  color: #000;
}

.choices .choices-table {
  width: 100%;
  margin-top: 1px;
  height: 50px;
  background: #fff;
  text-align: center;
  box-shadow: 3px 9px 9px rgba(0, 0, 0, 0.16);
}
.choices .choices-table .choices-icon {
  width: 20%;
  text-align: center;
}
.choices .choices-table .choices-title {
  width: 70%;
  text-align: left;
}
.choices .choices-text {
  margin-top: 2%;
  background-color: rgb(255, 255, 255);
}

.profile-table :hover {
  background-color: rgb(223, 223, 223);
}

.choices-table :active {
  background-color: rgb(223, 223, 223);
}
a:visited {
  color: inherit;
}
a {
  color: #000;
}

/* table */
.profile-card-table table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  height: auto;
  border-collapse: collapse;
  table-layout: fixed;
  word-break: break-all;
  word-wrap: break-all;
}

.profile-card-table table tr {
  width: 50px;
}

.profile-card-table table tr:last-child {
  border-bottom: none;
}

.profile-card-table table th {
  position: relative;
  text-align: left;
  width: 30%;
  height: auto;
  background: #fafafa;
  color: rgb(0, 0, 0);
  /* text-align: center; */
  padding: 10px 2px;
  font-size: 14px;
  font-weight: 400;
  vertical-align: top;
}
.profile-card-table table td {
  position: relative;
  text-align: left;
  height: auto;
  background: #fafafa;
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
}

.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.menu {
  float: right;
  margin-top: 59px;
  width: 70%;
  height: 80%;
  background-color: #ffffff;
}
.qualification {
  padding-right: 10px;
}

.qualification-div a {
  display: inline-block;
  margin: 0 0.1em 0.6em 0;
  padding: 0.8em;
  line-height: 1;
  text-decoration: none;
  background: #ededed;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
}
.qualification-div {
  display: inline-flex;
  padding: 4px;
  overflow: auto;
}
.space {
  width: 100%;
  height: 200px;
  background-color: rgb(255, 255, 255);
}
.icon-div {
  padding: 10px;
  height: 50px;
  width: 50px;
}
.alert-div {
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  width: 80%;
  height: 200px;
  background-color: #fff;
  border-radius: 5px;
}
.alert-msg {
  padding: 15% 5% 5% 5%;
}
.button-div table {
  margin-top: 15px;
  width: 100%;
}
.button-style {
  width: 100px;
  height: 40px;
  border: 1px solid #dddddd;
}
.cancel-button {
  border-radius: 10px;
  background-color: #fff;
  color: #888888;
  font-size: 14px;
  font-weight: 600;
}
.send-button {
  border-radius: 10px;
  background-color: #fff;
  color: #00aa93;
  font-size: 14px;
  font-weight: 600;
}
.msg-color {
  padding: 1% 1% 1% 1%;
  color: rgb(161, 0, 0);
}

/* 画像表示に関して */
.img-div {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.img-div .image-list {
  width: 180px;
  height: 180px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  /* 白線レイアウト 追加箇所 */
  border: 1px solid #bbbbbb;
}
.img-div img {
  background-color: #858585;
  border-radius: 50%;
  margin-top: 1px;
  margin-left: -4px;
}
.img-fiex {
  display: flex;
  flex-wrap: wrap;
}
/* /  画像表示に関して */

.open-img-modal {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.9);
  position: fixed;
  top: 0;
  left: 0;
}
.open-image-div {
  width: 100%;
  height: 70vh;
}
.close-image {
  margin-top: 60px;
  width: 50px;
  height: 50px;
}
.open-image {
  margin-top: 70px;
  width: 96vw;
  height: auto;
  max-height: 70vh;
  background-color: #fff;
}
.program-icon {
  width: 80px;
  height: 80px;
}
.mk-program-icon {
  text-align: center;
  width: 100%;
  margin: auto;
  /* padding: 8%; */
}
.program-font-style {
  text-align: center;
  color: rgb(90, 90, 90);
  font-weight: bold;
  font-size: 12px;
  padding: 6px;
}
.image-padding {
  padding: 4px;
}
</style>
