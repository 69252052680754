import moment from "moment";
import firebase from "firebase/compat";
// Globalパラメータ
export default {
  data() {
    return {
      date: {
        // 年
        year: [],
        // 月
        month: [],
        // 日
        day: [],
        // 曜日
        week: [],
        // 時間
        time: [],
      },
      //Mixアクセステスト 削除予定
      searchParm: "検索",
    };
  },
  computed: {
    hello: function () {
      return this.greeting + " " + this.world + "!";
    },
  },
  methods: {
    // 共通関数宣言時 glb_ 付与

    // 時間フォーマット操作:
    glb_toDate(str) {
      var arr = str.split("/");
      return new Date(arr[0], arr[1] - 1, arr[2]);
    },
    glb_to_yyymmdd(value) {
      //日付はYYYY/MM/DDに変更
      return moment(value).format("YYYY/MM/DD");
    },
    glb_to_yyymdd(value) {
      // 日付はYYYY/MM/DDに変更
      return moment(value).format("YYYY/M/DD");
    },
    glb_cf_date_after(value1, value2) {
      if (moment(value2, "HH:mm") >= moment(value1, "HH:mm")) {
        return true
      } else {
        return false
      }
    },
    glb_cf_date_before(value1, value2) {
      if (moment(value2, "HH:mm") <= moment(value1, "HH:mm")) {
        return true
      } else {
        return false
      }
    },
    glb_get_time_h() {
      let data = [];
      for (let i = 1; i <= 24; i++) {
        var j = i;
        if (j < 10) {
          j = "0" + j;
        }
        data.push({
          text: j,
          value: i,
        });
      }
      return data;
    },
    glb_get_time_m() {
      let data = [];
      for (let i = 0; i < 60; i = i + 5) {
        var j = i;
        if (j < 10) {
          j = "0" + j;
        }
        data.push({
          text: j,
          value: i,
        });
      }
      return data;
    },

    //時間設定：firestore timestamp
    glb_get_timestamp_now() {
      const nowData = new Date();
      return firebase.firestore.Timestamp.fromDate(nowData);
    },
    //時間設定：date -> firestore timestamp
    glb_get_date_to_timestamp(value) {
      return firebase.firestore.Timestamp.fromDate(new Date(value));
    },
    //クラス申請フォームチェック
    glb_check_class_register(value) {
      if (value.class_name == null || value.class_name == "") {
        return false;
      }
      if (value.lesson_name == null || value.lesson_name == "") {
        return false;
      }
      if (value.coach_name == null || value.coach_name == "") {
        return false;
      }
      if (value.location == null || value.location == "") {
        return false;
      }
      if (value.location_zip == null || value.location_zip == "") {
        return false;
      }
      if (value.location_address == null || value.location_address == "") {
        return false;
      }
      if (value.price == null || value.price == "") {
        return false;
      }
      if (value.contact == null || value.contact == "") {
        return false;
      }
      return true;
    },
    //非定期の時間チェック処理
    glb_check_class_date(value) {
      var checkValue = true;
      for (let i = 0; i < value.length; i++) {
        if (value[i].start_time == null || value[i].start_time == "") {
          checkValue = false;
          return checkValue;
        }
        if (value[i].end_time == null || value[i].end_time == "") {
          checkValue = false;
          return checkValue;
        }
      }
      return checkValue;
    },
    //定期の時間チェック処理
    glb_check_class_time(value) {
      var checkValue = true;
      for (let i = 0; i < value.length; i++) {
        //開始時間未入力
        if (value[i].start_time == null || value[i].start_time == "") {
          //終了時間未入力
          if (value[i].end_time == null || value[i].end_time == "") {
            //該当曜日入力されていない、削除
            //value.splice(i, 1);
          } else {
            //時間不正入力
            checkValue = false;
            return false;
          }
        } else {
          //終了時間未入力
          if (value[i].end_time == null || value[i].end_time == "") {
            //時間不正入力
            checkValue = false;
            return false;
          }
        }
      }
      return checkValue;
    },
    glb_set_weekDay_checkList() {
      let data = [];
      for (var i = 0; i < 7; i++) {
        var key = "";
        switch (i) {
          case 0:
            key = "月";
            break;
          case 1:
            key = "火";
            break;
          case 2:
            key = "水";
            break;
          case 3:
            key = "木";
            break;
          case 4:
            key = "金";
            break;
          case 5:
            key = "土";
            break;
          case 6:
            key = "日";
            break;
        }
        data.push({
          text: key,
          value: false,
        });
      }
      return data;
    },
    // fireBase(timeStamp)からDate型へ変換
    toDateTime(timeValue) {
      let dateTime = timeValue.toDate();
      let dayOfWeek = dateTime.getDay();
      let dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek];

      let formatted_date =
        dateTime.getFullYear() +
        "/" +
        (dateTime.getMonth() + 1) +
        "/" +
        dateTime.getDate() +
        " (" +
        dayOfWeekStr +
        ")" +
        dateTime.getHours().toString().padStart(2, '0') +
        ":" +
        dateTime.getMinutes().toString().padStart(2, '0');

      //形式 : 2022年00月00日00時00分 ◯曜日
      return formatted_date;
    },
    toDateYYYYMMDD(timeValue) {
      let dateTime = timeValue.toDate();
      var y = dateTime.getFullYear();
      var m = ('00' + (dateTime.getMonth() + 1)).slice(-2);
      var d = ('00' + dateTime.getDate()).slice(-2);

      return y + "/" + m + "/" + d;
    },

    // fireBase(timeStamp)から時間のみ変換
    toJustTime(timeValue) {
      let Time = timeValue.toDate();

      // let dayOfWeek = dateTime.getDay();

      let formatted_time = Time.getHours().toString().padStart(2, '0') + ":" + Time.getMinutes().toString().padStart(2, '0');

      //形式 : 2022年00月00日00時00分 ◯曜日
      return formatted_time;
    },
  },
};
