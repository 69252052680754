<template>
  <!-- Map画面でページ最下部のクラスリスト押下時 表示される (Modal) -->
  <div>
    <div v-if="showClassInfo" class="show-class-info">
      <ClassInfo @my-click="closeClassInfo" />
    </div>
    <div>
      <div
        class="change-div"
        v-for="content in classInfoList"
        v-bind:key="content.id"
      >
        <table @click="doClassInfo(content.class_uid)">
          <tr>
            <th>
              <div
                class="image_circle"
                :style="{
                  backgroundImage: 'url(' + content.class_icon.image_url + ')',
                }"
              ></div>
            </th>
            <td>
              <!-- 概要タイトル -->
              <div class="card-title">
                <table>
                  <tr>
                    <th>
                      <h5>場所名</h5>
                    </th>
                    <td>
                      <h5>{{ content.location }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h5>レッスン</h5>
                    </th>
                    <td>
                      <h5>{{ content.lesson_name }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h5>コーチ</h5>
                    </th>
                    <td>
                      <h5>{{ content.coach_name }}</h5>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div>
        <div class="back-map" @click="doBackMap()">
          <h4 class="back-map-font">マップで表示</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import firebase from "firebase/compat";
import ClassInfo from "@/components/class/modal/class_info_pad";
export default {
  name: "ClassList",
  props: {
    searchClass: Array,
  },
  components: {
    ClassInfo,
  },
  data() {
    return {
      image_url:
        "https://orthostudio.ca/wp-content/uploads/2016/11/image-3.jpg",
      classInfoList: [],
      searchResVar: false,
      showClassInfo: false,
    };
  },
  watch: {
    searchClass: {
      async handler(val) {
        await this.searchRes(val);
      },
      deep: true,
    },
  },
  created() {
    this.setScroll();
  },
  mounted() {
    this.getClassInfoList();
  },
  methods: {
    async searchRes(val) {
      // let cnt = 0;
      let self = this;
      self.classInfoList = val;
    },
    async doClassInfo(class_uid) {
      await this.glb_set_localStorage("classID", class_uid);
      this.showClassInfo = true;
    },
    async getClassInfoList() {
      let self = this;
      await firebase
        .firestore()
        .collection(self.glb_table.class)
        .get()
        .then(async function(querySnapshot) {
          let cnt = 0;
          await querySnapshot.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection(self.glb_table.class)
              .doc(doc.id)
              .get()
              .then(async (snap) => {
                //各クラスの情報を取得する
                if (
                  snap.data().status == "公開" &&
                  snap.data().inspection == self.glb_inspection.examined
                ) {
                  if (
                    snap.data().lat_and_lng.lat != null &&
                    snap.data().lat_and_lng.lat != "" &&
                    snap.data().lat_and_lng.lng != null &&
                    snap.data().lat_and_lng.lng != ""
                  ) {
                    let data = snap.data();
                    //data.id = Math.floor(Math.random() * 1000);
                    data.class_uid = doc.id;
                    self.classInfoList.push(data);
                    cnt = cnt + 1;
                    if (cnt == querySnapshot.forEach.length) {
                      self.classInfoList = await self.glb_set_random_for_list(
                        self.classInfoList
                      );
                    }
                  }
                }
              });
          });
        });
    },
    setScroll() {
      clearAllBodyScrollLocks();
    },
    doBackMap() {
      this.$emit("do-back-map", true);
    },
    closeClassInfo() {
      this.showClassInfo = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.change-div {
  overflow: auto;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 40%;
  height: 120px;
  background: rgb(255, 255, 255);
  /* background-color: black; */
  border-radius: 15px;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}
.change-div table {
  width: 100%;
  height: 100%;
}
.change-div table th {
  width: 120px;
  /* background-color: #fff; */
}
.change-div table td {
  position: relative;
}

.change-div table td .card-title {
  position: absolute;
  top: 12px;
  left: 0px;
  text-align: left;
}
.change-div table td .card-content {
  position: absolute;
  top: 35px;
  left: 0px;
  height: 40px;
  text-align: left;
  width: 100%;
  overflow: auto;
}

.image_circle {
  text-align: center;
  height: 100px;
  width: 100px;
  margin: 5px;
  border-radius: 10%;
  background-position: 54% 36%;
  background-size: cover;
}
.back-map {
  position: fixed;
  z-index: 3;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(80, 80, 80, 0.9);
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}
.back-map-font {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 12px;
}
.card-title {
  overflow: scroll;
  /* background-color: aquamarine; */
}
.card-title table {
  width: 98%;
}
.card-title table th {
  width: 25%;
  /* background-color: #f3f3f3; */
  color: rgb(102, 102, 102);
  font-weight: 500;

  /* border-bottom: 1px solid rgb(216, 216, 216); */
}
.card-title table td {
  /* background-color: #f0f0f0; */
  /* border-bottom: 1px solid rgb(216, 216, 216); */
}
.show-class-info {
  z-index: 11;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}
</style>
