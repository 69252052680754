<template>
  <div class="allEllement">
    <div id="header">
      <ClassHeader :page="3" @my-click="closePointInfo" />
      <div class="heard-style">
        <!--  HeaderBackButtonEllement-->
        <table class="heard-table">
          <tr>
            <td class="back-icon">
              <a href="#/profile">
                <img src="@/assets/home/yajirusi.svg" width="20" height="20" />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="showClassInfo" class="show-class-info">
      <ClassInfo @my-click="closeClassInfo" />
    </div>
    <div class="PointInfoPr-div">
      <PointInfoPr />
    </div>
    <div class="change-div-class">
      <div class="title-div">
        <p class="h3 title-font">開催中クラス一覧</p>
      </div>
      <div
        class="change-div"
        v-for="content in pointClassInfoList"
        :key="content.id"
      >
        <table @click="doClassInfo(content.class_uid)">
          <tr>
            <th>
              <div
                class="image_circle"
                :style="{
                  backgroundImage: 'url(' + content.class_icon.image_url + ')',
                }"
              ></div>
            </th>
            <td>
              <div class="card-title">
                <table>
                  <tr>
                    <th>
                      <h5>場所名</h5>
                    </th>
                    <td>
                      <h5>{{ content.location }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h5>レッスン</h5>
                    </th>
                    <td>
                      <h5>{{ content.lesson_name }}</h5>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h5>コーチ</h5>
                    </th>
                    <td>
                      <h5>{{ content.coach_name }}</h5>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="space-div"></div>
  </div>
</template>
<script>
// components/class/class_header
import ClassHeader from "@/components/class/class_header";
import PointInfoPr from "@/components/class/modal/point_info_profile";
import ClassInfo from "@/components/class/modal/class_info_pad";
import firebase from "firebase/compat";

export default {
  name: "ClassSearch",
  data() {
    return {
      point_info: {},
      pointClassInfoList: [],
      vicinityPointClassInfoList: [],
      showClassInfo: false,
    };
  },
  components: {
    ClassHeader,
    PointInfoPr,
    ClassInfo,
    // ClassNSN,
  },
  async mounted() {
    await this.getPointInfo();
    await this.getPointClassInfoList();
    //await this.getvicinityPointClassInfoList();
  },
  methods: {
    closeClassInfo() {
      this.showClassInfo = false;
    },
    async doClassInfo(class_uid) {
      await this.glb_set_localStorage("classID", class_uid);
      this.showClassInfo = true;
    },
    async getPointInfo() {
      //拠点情報取得
      let self = this;
      var pointUid = await this.glb_get_localStorage("point_uid");
      var pointLocation = await this.glb_get_localStorage("point_location");
      await firebase
        .firestore()
        .collection(self.glb_table.users)
        .doc(pointUid)
        .get()
        .then((snap) => {
          //各クラスの情報を取得する
          //各クラスの経緯度をマーカーに代入する
          let data = {};
          data = snap.data();
          const targetGuidance_base = snap
            .data()
            .guidance_base.find((v) => v.location === pointLocation);
          data.guidance_base = targetGuidance_base;
          self.point_info = data;
        });
    },
    async getPointClassInfoList() {
      //同じ経緯度のクラス取得
      let self = this;
      var pointUid = await this.glb_get_localStorage("point_uid");
      await firebase
        .firestore()
        .collection(self.glb_table.class)
        .where("trainer", "==", pointUid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (
              doc.data().status == "公開" &&
              doc.data().inspection == self.glb_inspection.examined
            ) {
              let data = doc.data();
              data.class_uid = doc.id;
              self.pointClassInfoList.push(data);
            }
          });
        });
    },
    closeModal() {
      this.$emit("closeModal", "false");
    },
    // クラス検索
    getOrganizeClass() {
      this.searchParm = "検索中";
    },
    toBack() {
      window.location.reload();
    },
    closePointInfo() {
      this.$emit("my-click", true);
    },
  },
};
</script>
<style scoped>
.allEllement {
  width: 100%;
  height: auto;
  margin: auto;
  /* min-height: 1400px; */
}
.PointInfoPr-div {
  width: 40%;
  height: auto;
  margin: auto;
  /* min-height: 1400px; */
}
.change-div-class {
  width: 40%;
  height: auto;
  margin: auto;
}
.card {
  width: 95%;
  height: auto;
  min-height: 80px;
  margin: 60px auto 0px auto;
  background: #fbfbfb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
}
.info-div {
  display: flex;
  width: 100%;
}
.card-title-div {
  /* text-align: center; */
  width: 100px;
  padding: 10px 10px 0 10px;
  text-align: left;
}
.card-title-font {
  font-weight: 650;
  color: rgb(109, 109, 109);
}
.card-content-div {
  width: 300px;
  overflow: auto;
  padding: 10px 10px 0 10px;
  text-align: left;
}
.card-content-font {
  line-height: 22px;
  color: rgb(22, 22, 22);
}
.qualification {
  /* display: flex; */
}
.title-div {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  height: 30px;
  text-align: left;
}
.title-font {
  text-align: left;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: #6b6b6b;
}
.change-div {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 95%;
  height: 100px;
  background: rgb(255, 255, 255);
  /* background-color: black; */
  border-radius: 15px;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}
.change-div table {
  width: 100%;
  height: 100%;
}
.change-div table th {
  width: 100px;
  padding: 0%;
  /* background-color: #fff; */
}
.change-div table td {
  overflow: auto;
  position: relative;
}
.change-div table td .card-title {
  position: absolute;
  top: 8px;
  left: 2px;
  text-align: left;
  padding: 0%;
  margin: 0%;
}
.change-div table td .card-content {
  position: absolute;
  top: 45px;
  left: 8px;
  height: 40px;
  width: 98%;
  text-align: left;
  overflow: auto;
}

.image_circle {
  text-align: center;
  height: 100px;
  width: 100px;
  border-radius: 10%;
  background-position: 54% 36%;
  background-size: cover;
  margin: 0%;
}
.space-div {
  width: 100%;
  height: 120px;
}
.card-title {
  overflow: scroll;
  /* background-color: aquamarine; */
}
.card-title table {
  width: 98%;
  margin: auto;
}
.card-title table th {
  width: 25%;
  /* background-color: #f3f3f3; */
  color: rgb(102, 102, 102);
  font-weight: 500;
}
.card-title table td {
  min-width: 120px;
  /* background-color: #f0f0f0; */
  /* border-bottom: 1px solid rgb(216, 216, 216); */
}
.show-class-info {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}
</style>
