<template>
  <div>
    <div v-if="showClassInfo" class="show-class-info">
      <ClassInfo @my-click="closeClassInfo" />
    </div>
    <div class="map" ref="googleMap"></div>
    <div class="change-div" @click="openClass()">
      <table v-if="class_info_list.length > 0">
        <tr>
          <th>
            <div
              class="image_circle"
              :style="{
                backgroundImage:
                  'url(' +
                  class_info_list[click_index].class_icon.image_url +
                  ')',
              }"
            ></div>
          </th>
          <td>
            <div class="card-title">
              <table>
                <tr>
                  <th>
                    <h5>場所名</h5>
                  </th>
                  <td>
                    <h5>{{ class_info_list[click_index].location }}</h5>
                  </td>
                </tr>
                <tr>
                  <th>
                    <h5>レッスン</h5>
                  </th>
                  <td>
                    <h5>{{ class_info_list[click_index].lesson_name }}</h5>
                  </td>
                </tr>
                <tr>
                  <th>
                    <h5>コーチ</h5>
                  </th>
                  <td>
                    <h5>{{ class_info_list[click_index].coach_name }}</h5>
                  </td>
                </tr>
                <tr>
                  <th>
                    <h5>開催日</h5>
                  </th>
                  <td>
                    <h5>
                      {{
                        toTime(
                          class_info_list[click_index].irregular_organize[0]
                            .start_time
                        )
                      }}
                    </h5>
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat";
import ClassInfo from "@/components/class/modal/class_info_pad";
export default {
  name: "ClassMap",
  props: {
    searchClass: Array,
    google: null,
  },
  components: {
    ClassInfo,
  },
  data() {
    return {
      // google: null,
      listModal: false,
      mapConfig: {
        // 座標
        center: {
          lat: 35.68944,
          lng: 136.69167,
        },
        zoom: 5,
        streetViewControl: false, //ストリートビューのマンアイコン削除
        zoomControl: false, //ズーム、縮小アイコンの削除
      },
      // クラス情報リスト
      class_info_list: [],
      class_info_regular: [], //定期用,審査済みデータ格納用
      class_info_noregular: [], //不定期用,審査済みデータ格納用
      class_info_joinlist: [], //定期用,不定期用,審査済みデータ結合用リスト
      map: {},
      marker: [],
      marker_back: [],
      infoWindow: [],
      photos: [],
      click_index: 0,
      click_class_id: "",
      defult_class_uid: "",
      click_event: false,
      searchResVar: false,
      search_class_cnt: 0,
      mapConfing: null,
      index: 0,
      setMap: null,
      showClassInfo: false,
    };
  },
  watch: {
    searchClass: {
      async handler(val) {
        if (val.length > 0) {
          if (this.search_class_cnt >= 0) {
            await this.searchRes(
              val[this.search_class_cnt],
              this.search_class_cnt
            );
            await this.setMarker(val, this.search_class_cnt, this.mapConfing);
          }
          this.search_class_cnt = this.search_class_cnt + 1;
        } else {
          this.marker = [];
          this.class_info_list = [];
          this.search_class_cnt = 0;
          this.mapConfing = new this.google.maps.Map(
            this.$refs.googleMap,
            this.mapConfig
          );
        }
      },
      deep: true,
    },
  },
  async mounted() {},
  async created() {
    await this.initializeMap();
    await this.setMapConfig();
    await this.toClassInfoByUrl();
  },
  methods: {
    async toClassInfoByUrl() {
      let url = new URL(window.location.href);
      let params = url.searchParams;
      var classId = params.get("id");
      if (classId != null) {
        await this.glb_set_localStorage("classID", classId);
        this.showClassInfo = true;
      }
    },
    setMapConfig() {
      if (this.setMap == null) {
        this.setMap = new this.google.maps.Map(
          this.$refs.googleMap,
          this.mapConfig
        );
      }
    },
    // 時間フォーマット FullData
    toTime(timeValue) {
      let TIME = this.toDateTime(timeValue);
      return TIME;
    },
    async setMarker(val, cnt, map) {
      let self = this;
      try {
        var lat_and_lng_data = await self.class_info_list[cnt].lat_and_lng;
        console.log(this.class_info_list[cnt].start_time);

        var randomVar = await Math.floor(Math.random() * 101);
        var test1 =
          (await lat_and_lng_data.lat.toString().substr(0, 7)) +
          randomVar.toString();
        var test2 =
          (await lat_and_lng_data.lng.toString().substr(0, 7)) +
          randomVar.toString();
        if (
          self.class_info_list[cnt].hold_status == null ||
          self.class_info_list[cnt].hold_status == self.glb_hold_status.not_held
        ) {
          self.marker.push(
            new self.google.maps.Marker({
              map: map,
              icon: {
                //url: self.glb_marker.blue,
                //開催予定
                url: self.glb_marker.yello,
                //scaledSize: new self.google.maps.Size(40, 40),
              },
              position: new self.google.maps.LatLng(test1, test2),
            })
          );
        } else {
          self.marker.push(
            new self.google.maps.Marker({
              map: map,
              icon: {
                // url: self.glb_marker.yello,
                //開催済
                url: self.glb_marker.gray,
                //scaledSize: new self.google.maps.Size(40, 40),
              },
              position: new self.google.maps.LatLng(test1, test2),
            })
          );
        }
        self.markerEvent(self.index, self.class_info_list[cnt].class_uid);
        self.index = self.index + 1;
      } catch (e) {
        self.index = self.index + 1;
      }
      self.click_index = Math.floor(
        Math.random() * self.class_info_list.length
      );
      // self.index = self.index + 1;
    },
    async searchRes(val) {
      let self = this;
      self.click_index = 0;
      self.class_info_list.push(val);
    },
    async initializeMap() {
      try {
        if (this.marker.length == 0) {
          this.marker = [];
          this.map = [];
          let self = this;

          await firebase
            .firestore()
            .collection(self.glb_table.class)
            .get()
            .then(function(regDatasnap) {
              let regcnt = 0;
              let regcntrem = 0;
              let noregcnt = 0;
              let noregcntrem = 0;
              //定期以外のデータの取得
              var sortnoregData = new Promise(function(resolve) {
                for (let noregData of regDatasnap.docs) {
                  firebase
                    .firestore()
                    .collection(self.glb_table.class)
                    .doc(noregData.id)
                    .get()
                    .then((snap) => {
                      //各クラスの情報を取得する
                      if (
                        snap.data().status == "公開" &&
                        snap.data().inspection == self.glb_inspection.examined
                      ) {
                        if (
                          snap.data().lat_and_lng.lat != null &&
                          snap.data().lat_and_lng.lat != "" &&
                          snap.data().lat_and_lng.lng != null &&
                          snap.data().lat_and_lng.lng != ""
                        ) {
                          let data = snap.data();
                          data.class_uid = noregData.id;
                          if (data.organize_type != "定期") {
                            self.class_info_noregular.push(data);
                            noregcnt = noregcnt + 1;
                            // console.log(noregcnt);
                            self.class_info_noregular.sort(function(
                              smalldata,
                              bigdata
                            ) {
                              return smalldata.irregular_organize[0]
                                .start_time <
                                bigdata.irregular_organize[0].start_time
                                ? -1
                                : 1;
                              //オブジェクトの昇順ソート
                              //20220411 変更
                            });
                            // promiseの完了判断を全件数一致で判断
                          } else {
                            noregcntrem = noregcntrem + 1;
                          }
                        } else {
                          noregcntrem = noregcntrem + 1;
                        }
                      } else {
                        noregcntrem = noregcntrem + 1;
                      }
                      // promiseの完了判断を全件数一致で判断
                      if (noregcnt + noregcntrem == regDatasnap.docs.length) {
                        return resolve(self.class_info_noregular);
                      }
                    });
                }
              });
              //定期のデータの取得
              var sortregData = new Promise(function(resolve) {
                for (let regData of regDatasnap.docs) {
                  firebase
                    .firestore()
                    .collection(self.glb_table.class)
                    .doc(regData.id)
                    .get()
                    .then((snap) => {
                      //各クラスの情報を取得する
                      if (
                        snap.data().status == "公開" &&
                        snap.data().inspection == self.glb_inspection.examined
                      ) {
                        if (
                          snap.data().lat_and_lng.lat != null &&
                          snap.data().lat_and_lng.lat != "" &&
                          snap.data().lat_and_lng.lng != null &&
                          snap.data().lat_and_lng.lng != ""
                        ) {
                          let data = snap.data();
                          data.class_uid = regData.id;
                          if (data.organize_type == "定期") {
                            self.class_info_regular.push(data);
                            regcnt = regcnt + 1;
                            // console.log(noregcnt);
                            // promiseの完了判断を全件数一致で判断
                          } else {
                            regcntrem = regcntrem + 1;
                          }
                        } else {
                          regcntrem = regcntrem + 1;
                        }
                      } else {
                        regcntrem = regcntrem + 1;
                      }
                      // promiseの完了判断を全件数一致で判断
                      if (regcnt + regcntrem == regDatasnap.docs.length) {
                        return resolve(self.class_info_regular);
                      }
                    });
                }
              });
              // sortnoregData,sortreDataが完了次第実行される
              Promise.all([sortnoregData, sortregData]).then(() => {
                // 定期用データの格納
                self.class_info_regular.forEach(function(reg) {
                  self.class_info_joinlist.push(reg);
                });
                // 非定期用データの格納
                self.class_info_noregular.forEach(function(reg) {
                  self.class_info_joinlist.push(reg);
                });

                let cnt = 0;
                self.class_info_joinlist.forEach(function(doc) {
                  //各クラスの情報を取得する
                  let data = doc;
                  data.class_uid = doc.class_uid;
                  self.class_info_list.push(data);
                  //各クラスの経緯度をマーカーに代入する
                  //経緯度+ランダム数字
                  let lat_and_lng_data = doc.lat_and_lng;
                  var randomVar = Math.floor(Math.random() * 101);
                  lat_and_lng_data.lat =
                    lat_and_lng_data.lat.toString().substr(0, 7) +
                    randomVar.toString();
                  lat_and_lng_data.lng =
                    lat_and_lng_data.lng.toString().substr(0, 7) +
                    randomVar.toString();
                  if (
                    doc.hold_status == null ||
                    doc.hold_status == self.glb_hold_status.not_held ||
                    doc.hold_status == ""
                  ) {
                    self.marker[cnt] = new self.google.maps.Marker({
                      map: self.setMap,
                      icon: {
                        // url: self.glb_marker.blue,
                        url: self.glb_marker.gray,
                        //scaledSize: new self.google.maps.Size(40, 40),
                      },
                      position: new self.google.maps.LatLng(
                        lat_and_lng_data.lat,
                        lat_and_lng_data.lng
                      ),
                      //zIndexの値が大きい方が上に来る ソート順変更
                      zIndex: cnt,
                    });
                  } else {
                    self.marker[cnt] = new self.google.maps.Marker({
                      map: self.setMap,
                      icon: {
                        // url: self.glb_marker.yello,
                        url: self.glb_marker.yello,
                      },
                      position: new self.google.maps.LatLng(
                        lat_and_lng_data.lat,
                        lat_and_lng_data.lng
                      ),
                      //zIndexの値が大きい方が上に来る ソート順変更
                      zIndex: cnt,
                    });
                  }
                  if (cnt == 0) {
                    self.defult_class_uid = doc.class_uid;
                  }
                  //Mapにピンを刺す
                  self.markerEvent(cnt, doc.class_uid);
                  cnt = cnt + 1;
                  //ランダムで表示
                  self.click_index = Math.floor(
                    Math.random() * self.class_info_list.length
                  );
                });
                console.log(self.class_info_list);
              });
            });

          // await firebase
          //   .firestore()
          //   .collection(self.glb_table.class)
          //   .get()
          //   .then(async function(querySnapshot) {
          //     let cnt = 0;
          //     await querySnapshot.forEach(async function(doc) {
          //       await firebase
          //         .firestore()
          //         .collection(self.glb_table.class)
          //         .doc(doc.id)
          //         .get()
          //         .then(async (snap) => {
          //           //各クラスの情報を取得する
          //           if (
          //             snap.data().status == "公開" &&
          //             snap.data().inspection == self.glb_inspection.examined
          //           ) {
          //             if (
          //               snap.data().lat_and_lng.lat != null &&
          //               snap.data().lat_and_lng.lat != "" &&
          //               snap.data().lat_and_lng.lng != null &&
          //               snap.data().lat_and_lng.lng != ""
          //             ) {
          //               let data = snap.data();
          //               data.class_uid = doc.id;
          //               self.class_info_list.push(data);
          //               //各クラスの経緯度をマーカーに代入する
          //               //経緯度+ランダム数字
          //               let lat_and_lng_data = snap.data().lat_and_lng;
          //               var randomVar = Math.floor(Math.random() * 101);
          //               lat_and_lng_data.lat =
          //                 lat_and_lng_data.lat.toString().substr(0, 7) +
          //                 randomVar.toString();
          //               lat_and_lng_data.lng =
          //                 lat_and_lng_data.lng.toString().substr(0, 7) +
          //                 randomVar.toString();
          //               if (
          //                 snap.data().hold_status == null ||
          //                 snap.data().hold_status ==
          //                   self.glb_hold_status.not_held ||
          //                 snap.data().hold_status == ""
          //               ) {
          //                 self.marker[cnt] = new self.google.maps.Marker({
          //                   map: self.setMap,
          //                   icon: {
          //                     url: self.glb_marker.blue,
          //                     //scaledSize: new self.google.maps.Size(40, 40),
          //                   },
          //                   position: new self.google.maps.LatLng(
          //                     lat_and_lng_data.lat,
          //                     lat_and_lng_data.lng
          //                   ),
          //                 });
          //               } else {
          //                 self.marker[cnt] = await new self.google.maps.Marker({
          //                   map: self.setMap,
          //                   icon: {
          //                     url: self.glb_marker.yello,
          //                   },
          //                   position: new self.google.maps.LatLng(
          //                     lat_and_lng_data.lat,
          //                     lat_and_lng_data.lng
          //                   ),
          //                 });
          //               }
          //               if (cnt == 0) {
          //                 self.defult_class_uid = doc.id;
          //               }

          //               self.markerEvent(cnt, doc.id);
          //               cnt = cnt + 1;
          //               //ランダムで表示
          //               self.click_index = Math.floor(
          //                 Math.random() * self.class_info_list.length
          //               );
          //             }
          //           }
          //         });
          //     });
          //   });
        }
      } catch (e) {
        console.log(e);
      }
    },
    markerEvent(value, class_id) {
      //マーカークリックすると、画面下部分をモーダル画面が出ます
      let self = this;
      self.marker[value].addListener("click", function() {
        self.click_index = value;
        self.click_class_id = class_id;
      });
    },
    closeClassInfo() {
      let url = new URL(window.location.href);
      var params = url.searchParams;
      params.delete("id");
      history.replaceState("", "", url.pathname);
      this.showClassInfo = false;
    },
    async openClass() {
      let url = new URL(window.location.href);
      this.click_class_id = this.class_info_list[this.click_index].class_uid;
      url.searchParams.append("id", this.click_class_id);
      location.href = url;
      await this.glb_set_localStorage("classID", this.click_class_id);
      this.showClassInfo = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.map-div {
  height: 100%;
}
.nav-div {
  width: 100%;
  height: 120px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.search {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border: none;
  outline: #fff;
}
.search-button {
  margin-left: auto;
  margin-right: auto;
  height: 46px;
  width: 95%;
  background: #eeeeee;
  border-radius: 20px;
  font-size: 16px;
  outline: #fff;
  border: none !important;
  color: #939393;
  text-align: left;
  padding-left: 20px;
}
.search-button :active {
  color: #939393;
}

.map {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 68vh;
}
.change-div {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: -150px;
  width: 40%;
  height: 100px;
  background: rgb(255, 255, 255);
  /* background-color: black; */
  border-radius: 15px;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}
.change-div table {
  width: 100%;
  height: 100%;
}
.change-div table th {
  width: 100px;
  padding: 0%;
  /* background-color: #fff; */
}
.change-div table td {
  overflow: auto;
  position: relative;
}

.change-div table td .card-title {
  position: absolute;
  width: 95%;
  top: 8px;
  left: 2px;
  text-align: left;
}
.change-div table td .card-content {
  position: absolute;
  top: 60px;
  left: 8px;
  height: 40px;
  text-align: left;
  overflow: auto;
}

.image_circle {
  text-align: center;
  height: 100px;
  width: 100px;
  border-radius: 10%;
  background-position: 54% 36%;
  background-size: cover;
  margin: 0%;
}

.change-button {
  display: flex;
}
.btn-border-bottom {
  /* margin-right: 10px; */
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  color: #6b6b6b;
  font-size: 16px;
}
.change-button-01 {
  padding: 0% 15px 0% 15px;
  border-bottom: 1px solid #9b9b9b; /* 線の太さ 線の種類 線の色 */
}

.tab-wrap {
  margin-top: 5px;
  flex-wrap: wrap;
  /* overflow: hidden; */
  padding: 0 0 20px;
}

.tab-label {
  color: Gray;
  cursor: pointer;
  flex: 1;
  font-weight: bold;
  order: -1;
  padding: 12px 24px;
  position: relative;
  text-align: center;
  transition: cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  user-select: none;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
}

.tab-label:hover {
  background: rgba(255, 255, 255, 0.1);
}

.tab-switch:checked + .tab-label {
  color: #6b6b6b;
}

.tab-label::after {
  background: #6b6b6b;
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: translateX(100%);
  transition: cubic-bezier(0.4, 0, 0.2, 1) 0.2s 80ms;
  width: 100%;
  z-index: 1;
}

.tab-switch:checked ~ .tab-label::after {
  transform: translateX(-100%);
}

.tab-switch:checked + .tab-label::after {
  opacity: 1;
  transform: translateX(0);
}

.tab-content {
  height: 0;
  opacity: 0;
  padding: 0 20px;
  pointer-events: none;
  transform: translateX(-30%);
  transition: transform 0.3s 80ms, opacity 0.3s 80ms;
  width: 100%;
}

.tab-switch:checked ~ .tab-content {
  transform: translateX(30%);
}

.tab-switch:checked + .tab-label + .tab-content {
  height: auto;
  opacity: 1;
  order: 1;
  pointer-events: auto;
  transform: translateX(0);
}

.tab-wrap::after {
  content: "";
  height: 20px;
  order: -1;
  width: 100%;
}

.tab-switch {
  display: none;
}
.class-list-div {
  position: relative;
  top: 70px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  border-radius: 8% 8% 0% 0%;
  color: rgb(0, 0, 0);
}
.list-modal-page {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100vh;
  animation: fadeIn 0.5s ease 0.1s 1 normal backwards;
  background-color: #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(600px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.card-title {
  overflow: scroll;
  /* background-color: aquamarine; */
}
.card-title table {
  width: 98%;
  margin: auto;
}
.card-title table th {
  width: 25%;
  /* background-color: #f3f3f3; */
  color: rgb(102, 102, 102);
  font-weight: 500;
}
.card-title table td {
  min-width: 120px;
  /* background-color: #f0f0f0; */
  /* border-bottom: 1px solid rgb(216, 216, 216); */
}
.show-class-info {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}
</style>
