// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from "vue-router";
import ResetPassword from "../views/mobile/ResetPassword.vue";
import Login from "../views/mobile/Login.vue";
import Class from "../views/mobile/Class.vue";
// import ClassInfo from "../components/class/class_map.vue"
// import Program from "../views/mobile/Program.vue";

import ClassPad from "../views/ipad/Class.vue";


let routes = [];
if (screen.width > 540) {
  routes = [
    {
      path: "/:catchAll(.*)",
      redirect: "login",
    },
    {
      path: "/dfmveigbnieridveir",
      name: "ResetPassword",
      component: ResetPassword,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/",
      name: "ClassPad",
      component: ClassPad,
      meta: { requiresAuth: true },
    },
  ];
} else {
  routes = [
    {
      path: "/:catchAll(.*)",
      redirect: "class",
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    // {
    //   path: "/class/:id",
    //   name: "ClassInfo",
    //   component: ClassInfo,
    //   meta: { requiresAuth: true },
    // },
    {
      path: "/",
      name: "Class",
      component: Class,
      meta: { requiresAuth: true },
    },

    // {
    //   path: "/program",
    //   name: "Program",
    //   component: Program,
    // },
  ];
}

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth) {
    next();
  } else {
    next(); // next() を常に呼び出すようにしてください!
  }
});

export default router;
