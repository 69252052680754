<template>
  <div>
    <ClassHeader :page="2" />
    <div class="allEllement">
      <transition name="modal">
        <div class="searchModalBody">
          <div class="input-form">
            <div class="input-group input-group-lg">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="inputKeyWord"
                  aria-describedby="emailHelp"
                  placeholder="キーワード"
                  v-model="search.keyWord"
                />
              </div>
              <div class="form-group">
                <select
                  class="custom-select select-form"
                  id="inputGroupSelect01"
                  v-model="search.prefecture"
                >
                  <option
                    v-for="option in prefecture_options"
                    v-bind:value="option.value"
                    :key="option.value"
                    >{{ option.text }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <select
                  class="custom-select select-form"
                  id="inputGroupSelect01"
                  v-model="search.organize_type"
                  @change="organize_type_change()"
                >
                  <option
                    v-for="option in date_select_options"
                    v-bind:value="option.value"
                    :key="option.value"
                    >{{ option.text }}
                  </option>
                </select>
              </div>

              <!-- <div class="input-form">
              <select
                class="custom-select select-form"
                id="inputGroupSelect01"
                v-model="organize_type"
              >
                <option
                  v-for="option in date_select_options"
                  v-bind:value="option.value"
                  :key="option.value"
                  >{{ option.text }}
                </option>
              </select>
            </div> -->
              <div
                class="d-flex justify-content-around"
                v-if="search.organize_type == '定期'"
              >
                <div class="checkbox-div">
                  <div class="input-group-prepend">
                    <div
                      class="input-group-text checkbox-style"
                      v-for="content in weekDayCheckList"
                      :key="content.id"
                    >
                      {{ content.text }}
                      <input
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                        v-model="content.value"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- <br /> -->
              <div
                class="form-group"
                v-else-if="search.organize_type == '不定期'"
              >
                <div class="datepicker-style">
                  <v-date-picker :popover="popover" v-model="search.date">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        :value="inputValue"
                        v-on="inputEvents"
                        class="datepicker"
                        placeholder="開催日付"
                      />
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <br />
              <table>
                <tr>
                  <td><p class="h5">開始時間</p></td>
                  <td><p class="h5">終了時間</p></td>
                </tr>

                <td>
                  <input
                    type="time"
                    class="form-control time"
                    id="input-sTime"
                    placeholder="開始時間"
                    v-model="search.start_time"
                  />
                </td>
                <td>
                  <input
                    type="time"
                    class="form-control time"
                    id="input-eTime"
                    placeholder="終了時間"
                    v-model="search.end_time"
                  />
                </td>
              </table>

              <div id="Btn">
                <button
                  id="cxlBtn"
                  type="button"
                  class="btn btn-outline-secondary w-auto p-2"
                  v-on:click="$emit('closeModal')"
                >
                  キャンセル
                </button>
                <button
                  id="searchBtn"
                  type="button"
                  class="btn btn-danger w-25 p-3"
                  @click="getOrganizeClass()"
                >
                  {{ searchParm }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import ClassHeader from "../../../components/class/class_header";

export default {
  name: "ClassSearch",
  props: {
    classList: Array,
  },
  data() {
    return {
      weekDayCheckList: [],
      date: null,
      organize_type: "定期",
      date_select_options: [
        {
          text: "開催種類",
          value: "-",
        },
        {
          text: "定期",
          value: "定期",
        },
        {
          text: "不定期",
          value: "不定期",
        },
      ],
      search: {
        keyWord: "",
        prefecture: 0,
        organize_type: "-",
        date: null,
        start_time: "",
        end_time: "",
      },
      // キーワード
      SearchWord: "",

      // 都道府県 = mixInから取得
      // 曜日検索
      WeekDay: "",
      // 開催日時
      Dates: "",
      // 開始時間
      StartTime: "",
      // 終了時間
      EndTime: "",
    };
  },
  created() {
    this.setWeekDay();
  },
  components: {
    ClassHeader,
  },
  methods: {
    organize_type_change() {
      if (this.search.organize_type == "定期") {
        this.search.date = null;
      } else if (this.search.organize_type == "不定期") {
        this.weekDayCheckList = this.glb_set_weekDay_checkList();
      } else {
        this.weekDayCheckList = this.glb_set_weekDay_checkList();
      }
    },
    setWeekDay() {
      //曜日設定
      // for (var i = 0; i < 7; i++) {
      //   this.weekDayCheckList.push(false);
      // }
      this.weekDayCheckList = this.glb_set_weekDay_checkList();
    },
    closeModal() {
      this.$emit("closeModal");
    },

    // クラス検索
    getOrganizeClass() {
      const targetWeek = this.weekDayCheckList.filter((v) => v.value);
      let targetWeekList = [];
      if (targetWeek.length > 0) {
        for (var i in targetWeek) {
          // if (targetWeek[)
          targetWeekList.push(targetWeek[i].text);
        }
      }
      this.search.targetWeek = targetWeekList;
      this.$emit("searcModal", this.search);
    },
  },
};
</script>
<style scoped>
/* 画面全体 */
.allEllement {
  position: absolute;
  width: 30%;
  height: auto;
  background-color: #ffffff;
  margin: auto;
  margin-top: 2%;
  left: 35%;
}
.alert-div {
  margin-top: 200px;
  font-size: 16px;
}
.search-form {
  margin-top: 20px;
}

#Btn {
  text-align: center;
  margin-top: 43%;
  font-size: 1rem;
}
.btn {
  width: 10%;
  height: 10%;
  font-size: 1rem;

  text-align: center;
  width: 100px;
  height: 35px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;

  box-shadow: 0px 3px 6px rgb(214 214 214);
}

#cxlBtn {
  margin-right: 7%;
}
.p-3 {
  padding: 0.6rem !important;
}

#searchBtn {
  color: white;
}

input {
  background-color: #eeeeee;
  font-size: 1.5rem;
}
.input-group {
  margin-top: 35%;
  display: block;
}
input[type="text"] {
  border: none;
  display: block;

  font-size: 12pt;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.input-form {
  margin-top: 16%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 95%;
}
.input-title {
  font-weight: 600;
}
.input-content {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
}
.form-group {
  text-align: center;
}
/* クラス検索対象時間 */
#orgTime {
  display: flex;
}
.time {
  /* margin-right: -12px; */
  /* display: inline; */
  /* width: 95%; */
  font-size: 16px;
  /* color: black; */
  font-weight: 500;
}

.datepicker-style {
  width: 100%;
}
.datepicker {
  /* font-size: 16px; */
  outline: none;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  height: 35px;
  font-weight: 500;
  padding: 5px;
  width: 94%;
  background: url(../../../assets/class/calendar.png) no-repeat 98% 10px;
  background-color: #ededed;
}
.checkbox-div {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}
.checkbox-flex {
  display: flex;
}
.checkbox-style {
  font-size: 16px;
  width: 100%;
  background-color: #ededed;
  /* border: none; */
  /* padding: 5px; */
}
.select-form {
  width: 94%;
  font-size: 16px;
  background-color: #ededed;
  border: none;
}
.label-time {
  width: 90%;
  /* padding-left: 100px; */
  margin: auto;
}
table {
  margin: auto;
  width: 94%;
  /* background-color: #da4747; */
}
table th {
  width: 50%;
}
table td {
  width: 50%;
  padding: 0%;
  /* margin: 0%; */
  text-align: left;
}
</style>
