import firebase from "firebase/compat";
export default {
  data() {
    return {
      image_url: "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/tool%2Ffitness_camp.png?alt=media&token=b43aef27-a0dd-400e-b357-48bceccbfe83"
    };
  },
  async mounted() {
    //await this.setGoogleConfing();

  },
  computed: {
    hello: function () {
      return this.greeting + " " + this.world + "!";
    },
  },
  methods: {
    async glb_get_class_info() {
      let data = []
      let self = this
      await firebase.firestore().collection("class").get().then(async function (querySnapshot) {
        await querySnapshot.forEach(async function (doc) {
          await firebase
            .firestore()
            .collection(self.glb_table.class)
            .doc(doc.id)
            .get()
            .then(
              snap => {
                data.push(snap.data())
              }
            )
        }).then(() => {
        })
      })

      return data
    },
    glb_set_default_image() {
      let data = {
        image_name: "default",
        image_url: this.image_url
      }
      return data
    },
    //ファイルをアップロード、urlを戻す
    async glb_upload_file(path, value) {
      var urlData = ""
      let fileRef = firebase
        .storage()
        .ref()
        .child(path);
      await fileRef
        .put(value)
        .then(async () => {
          //console.log(res);
          //アイコン画像のURLを取得
          await fileRef.getDownloadURL().then(url => {
            urlData = url
          });
        })
      return urlData
    },
    async glb_get_user_uid() {
      var data = ""
      await firebase.auth().onAuthStateChanged(async function (user) {
        // console.log(user)
        data = user.uid
      })
      return data
    },
    //クラス申込者テーブルにデータそ挿入
    async glb_set_class_applicant(class_uid, user_info) {
      let self = this
      let checkVar = false
      //各ユーザーUIDをつけ
      user_info.id = await this.glb_get_UUID()
      //class uidでデータ挿入
      await firebase
        .firestore()
        .collection(self.glb_table.class_applicant)
        .doc(class_uid)
        .update({ applicant_list: firebase.firestore.FieldValue.arrayUnion(user_info) })
        .then(async () => {
          //this.success = true;
          checkVar = true
        })
        .catch(() => {
          checkVar = false
        });
      return checkVar
    }
  },
};
