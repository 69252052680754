export default {
  data() {
    return {

    };
  },
  async mounted() {
    // self.glb_table.qualification
  },
  computed: {

  },
  methods: {
    async glb_search(search_key, search_target) {
      let searchTarget = [];
      //検索：01:キーワード
      if (search_key.keyWord != null && search_key.keyWord != "") {
        //検索キーワードがあり
        var searchData =
          search_target.application_process +
          search_target.class_name +
          search_target.coach_name +
          search_target.contact +
          search_target.lesson_name +
          search_target.location +
          search_target.location_address +
          search_target.location_zip +
          search_target.price +
          search_target.remarks;
        var resultIndex = searchData.indexOf(search_key.keyWord);
        if (resultIndex >= 0) {
          searchTarget.push(true);
        } else {
          searchTarget.push(false);
        }
      }
      if (search_key.prefecture != 0) {
        var reprefectureIndex = search_target.location_address.indexOf(search_key.prefecture);
        if (reprefectureIndex < 0) {
          searchTarget.push(false);
          // return;
        } else {
          searchTarget.push(true);
        }
      }
      //開催種類を検索
      if (search_key.organize_type != "-") {
        if (search_key.organize_type == "定期") {
          if (search_target.organize_type == "定期") {
            if (search_key.targetWeek.length == 0) {
              searchTarget.push(true);
            } else if (search_key.targetWeek.length > 0) {
              if (search_key.targetWeek.length > 0) {
                let targetWeekBool = false;
                for (var i in search_target.fixed_term_organize) {
                  if (
                    search_target.fixed_term_organize[i]
                      .start_time != "" ||
                    search_target.fixed_term_organize[i].end_time !=
                    ""
                  ) {
                    if (
                      search_key.targetWeek.includes(
                        search_target.fixed_term_organize[i].date
                      )
                    ) {
                      targetWeekBool = true;
                    }
                  }
                }
                searchTarget.push(targetWeekBool);
              }
            }
          }
          //曜日チェックする
        } else {
          if (search_target.organize_type == "不定期") {
            if (search_key.date != null) {
              var searchTargetDate = await self.glb_to_yyymmdd(
                search_key.date
              );
              let targetDateBool = false;
              for (var j in search_target.irregular_organize) {
                var dateSearch = await self.toDateYYYYMMDD(
                  search_target.irregular_organize[j].date
                );
                if (dateSearch == searchTargetDate) {
                  targetDateBool = true;
                }
              }
              searchTarget.push(targetDateBool);
            } else {
              searchTarget.push(true);
            }
          } else {
            searchTarget.push(false);
          }
        }
      }

      //開始時間と終了時間検索
      if (search_key.start_time != "" || search_key.end_time != "") {
        if (search_target.organize_type == "定期") {
          let fixedDateBool = false;

          for (var k in search_target.fixed_term_organize) {
            if (
              search_target.fixed_term_organize[k].start_time !=
              "" ||
              search_target.fixed_term_organize[k].end_time != ""
            ) {

              if (
                search_key.start_time != "" &&
                search_key.end_time != ""
              ) {
                if (
                  (await this.glb_cf_date_after(
                    search_key.start_time,
                    search_target.fixed_term_organize[k].start_time
                  )) &&
                  (await this.glb_cf_date_before(
                    search_key.end_time,
                    search_target.fixed_term_organize[k].end_time
                  ))
                ) {

                  fixedDateBool = true;
                }
              } else if (
                search_key.start_time != "" &&
                search_key.end_time == ""
              ) {
                //開始時間
                if (
                  await this.glb_cf_date_after(
                    search_key.start_time,
                    search_target.fixed_term_organize[k].start_time
                  )
                ) {
                  fixedDateBool = true;
                }
              } else {
                //終了時間
                if (
                  await this.glb_cf_date_before(
                    search_key.end_time,
                    search_target.fixed_term_organize[k].end_time
                  )
                ) {
                  fixedDateBool = true;
                }
              }
            }
          }
          searchTarget.push(fixedDateBool);
        } else {
          let irregularDateBool = false;
          for (var y in search_target.irregular_organize) {
            if (
              search_target.irregular_organize[y].start_time !=
              "" ||
              search_target.irregular_organize[y].end_time != ""
            ) {
              if (
                search_key.start_time != "" &&
                search_key.end_time != ""
              ) {
                if (
                  (await this.glb_cf_date_after(
                    search_key.start_time,
                    await this.toJustTime(search_target.irregular_organize[y].start_time)
                  )) &&
                  (await this.glb_cf_date_before(
                    search_key.end_time,
                    await this.toJustTime(search_target.irregular_organize[y].end_time)
                  ))
                ) {
                  irregularDateBool = true;
                }
              } else if (
                search_key.start_time != "" &&
                search_key.end_time == ""
              ) {
                //開始時間
                if (
                  await this.glb_cf_date_after(
                    search_key.start_time,
                    await this.toJustTime(search_target.irregular_organize[y].start_time)
                  )
                ) {
                  irregularDateBool = true;
                }
              } else {
                //終了時間
                if (
                  await this.glb_cf_date_before(
                    search_key.end_time,
                    await this.toJustTime(search_target.irregular_organize[y].end_time)
                  )
                ) {
                  irregularDateBool = true;
                }
              }
            }
          }
          searchTarget.push(irregularDateBool);
        }
      }
      if (!searchTarget.includes(false)) {
        return true
      } else {
        return false
      }
    },
  }
};

