<template>
  <div>
    <div v-if="showPointInfo" class="show-class-info">
      <PointInfo @my-click="closePointInfo" />
    </div>
    <div class="map" ref="p_googleMap"></div>
    <div v-if="point_info_list.length > 0">
      <div
        class="change-div"
        @click="
          toPointInfo(
            point_info_list[click_index].uid,
            point_info_list[click_index].guidance_base.location
          )
        "
      >
        <table>
          <tr>
            <th>
              <!-- <img :src="image_url" /> -->
              <div
                class="image_circle"
                :style="{
                  backgroundImage:
                    'url(' + point_info_list[click_index].icon + ')',
                }"
              ></div>
            </th>
            <td>
              <div class="card-title">
                <table>
                  <tr>
                    <th>
                      <h5>名前</h5>
                    </th>
                    <td>
                      <h5>
                        {{ point_info_list[click_index].certified_coach }}
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h5>指導拠点</h5>
                    </th>
                    <td>
                      <h5>
                        {{
                          toSubstring(
                            point_info_list[click_index].guidance_base.location
                          )
                        }}
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h5>プロフィール</h5>
                    </th>
                    <td>
                      <h5>
                        {{ toSubstring(point_info_list[click_index].self_pr) }}
                      </h5>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat";
import PointInfo from "@/components/class/modal/point_info_pad";
export default {
  name: "PointMap",
  props: {
    searchPoint: Array,
    p_google: null,
  },
  components: {
    PointInfo,
  },
  data() {
    return {
      listModal: false,
      image_url:
        "https://orthostudio.ca/wp-content/uploads/2016/11/image-3.jpg",
      p_def_mapConfig: {
        center: {
          lat: 35.68944,
          lng: 136.69167,
        },
        zoom: 5,
        streetViewControl: false, //ストリートビューのマンアイコン削除
        zoomControl: false, //ズーム、縮小アイコンの削除
      },
      point_info_list: [],
      marker_1: [],
      infoWindow: [],
      photos: [],
      click_index: 0,
      geocoder: null,
      search_Point_cnt: 0,
      index: 0,
      // google map
      p_map: {},
      p_marker: [],
      p_mapConfing: null,
      p_setMap: null,
      showPointInfo: false,
    };
  },
  watch: {
    searchPoint: {
      async handler(val) {
        if (val.length > 0) {
          if (this.search_Point_cnt >= 0) {
            await this.searchRes(
              val[this.search_Point_cnt],
              this.search_Point_cnt
            );
            await this.setMarker(val, this.search_Point_cnt, this.p_mapConfing);
          }
          this.search_Point_cnt = this.search_Point_cnt + 1;
        } else {
          this.p_marker = [];
          this.point_info_list = [];
          this.search_Point_cnt = 0;
          this.p_mapConfing = new this.p_google.maps.Map(
            this.$refs.p_googleMap,
            this.p_def_mapConfig
          );
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.initializeMap_p();
    await this.setMapConfig_p();
  },
  methods: {
    setMapConfig_p() {
      if (this.p_setMap == null) {
        this.p_setMap = new this.p_google.maps.Map(
          this.$refs.p_googleMap,
          this.p_def_mapConfig
        );
      }
    },
    async setGoogleConfing_p() {},
    async setMarker(val, cnt, map) {
      let self = this;
      var lat_and_lng_data = await self.point_info_list[cnt].guidance_base;

      var randomVar = await Math.floor(Math.random() * 101);
      var test1 =
        (await lat_and_lng_data.lat.toString().substr(0, 7)) +
        randomVar.toString();
      var test2 =
        (await lat_and_lng_data.lng.toString().substr(0, 7)) +
        randomVar.toString();
      try {
        self.p_marker.push(
          new self.p_google.maps.Marker({
            map: map,
            icon: {
              url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
              //scaledSize: new self.p_google.maps.Size(40, 40),
            },
            position: new self.p_google.maps.LatLng(test1, test2),
          })
        );
        self.markerEvent(self.index);
        self.index = self.index + 1;
      } catch (e) {
        self.index = self.index + 1;
      }
      self.click_index = Math.floor(
        Math.random() * self.point_info_list.length
      );
    },
    async searchRes(val) {
      let self = this;
      self.click_index = 0;
      self.point_info_list.push(val);
    },
    toSubstring(value) {
      if (value != "" && value != null) {
        if (value.length > 20) {
          return value.substring(0, 20) + "...";
        } else {
          return value;
        }
      } else {
        if (value == null) {
          return "";
        }
        return value;
      }
    },
    async initializeMap_p() {
      try {
        //01:クラスを取得
        if (this.p_marker.length == 0) {
          let self = this;
          this.p_marker = [];
          this.p_map = [];

          await firebase
            .firestore()
            .collection(self.glb_table.users)
            .get()
            .then(async function(querySnapshot) {
              let cnt = 0;
              await querySnapshot.forEach(async function(doc) {
                await firebase
                  .firestore()
                  .collection(self.glb_table.users)
                  .doc(doc.id)
                  .get()
                  .then((snap) => {
                    //各クラスの情報を取得する
                    //self.class_info_list.push(snap.data());
                    //各クラスの経緯度をマーカーに代入する
                    if (snap.data().guidance_base != null) {
                      for (var i in snap.data().guidance_base) {
                        if (snap.data().guidance_base[i].location != null) {
                          let locationData = snap.data().guidance_base[i];
                          let data = snap.data();
                          if (
                            snap.data().uid == null ||
                            snap.data().uid == ""
                          ) {
                            data.uid = doc.id;
                          }
                          data.guidance_base = locationData;
                          self.point_info_list.push(data);

                          //経緯度+ランダム数字
                          let lat_and_lng_data = data.guidance_base;
                          var randomVar = Math.floor(Math.random() * 101);
                          lat_and_lng_data.lat =
                            lat_and_lng_data.lat.toString().substr(0, 7) +
                            randomVar.toString();
                          lat_and_lng_data.lng =
                            lat_and_lng_data.lng.toString().substr(0, 7) +
                            randomVar.toString();

                          self.p_marker[cnt] = new self.p_google.maps.Marker({
                            map: self.p_setMap,
                            icon: {
                              url:
                                "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
                              //scaledSize: new self.p_google.maps.Size(40, 40),
                            },
                            position: new self.p_google.maps.LatLng(
                              lat_and_lng_data.lat,
                              lat_and_lng_data.lng
                            ),
                          });
                          self.markerEvent(cnt);
                          cnt = cnt + 1;
                        }
                      }
                    }
                    // ランダムで表示
                    self.click_index = Math.floor(
                      Math.random() * self.point_info_list.length
                    );
                  });
              });
            });
        }
      } catch (e) {
        console.log(e);
      }
    },
    markerEvent(value) {
      let self = this;
      self.p_marker[value].addListener("click", function() {
        self.click_index = value;
      });
    },
    doSearch() {},
    openList() {
      this.listModal = true;
    },
    doBackMap(value) {
      if (value) {
        this.listModal = !value;
      }
    },
    toPointInfo(uid, location) {
      //拠点UIDはlocalStorgeに保存：userのUID
      this.glb_set_localStorage("point_uid", uid);
      this.glb_set_localStorage("point_location", location);
      this.showPointInfo = true;
    },
    closePointInfo() {
      this.showPointInfo = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.map-div {
  height: 100%;
}
.nav-div {
  width: 100%;
  height: 120px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.search {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border: none;
  outline: #fff;
}
.search-button {
  margin-left: auto;
  margin-right: auto;
  height: 46px;
  width: 95%;
  background: #eeeeee;
  border-radius: 20px;
  font-size: 16px;
  outline: #fff;
  border: none !important;
  color: #939393;
  text-align: left;
  padding-left: 20px;
}
.search-button :active {
  color: #939393;
}

.map {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 68vh;
}
.change-div {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: -140px;
  width: 40%;
  height: 100px;
  background: rgb(255, 255, 255);
  /* background-color: black; */
  border-radius: 15px;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}
.change-div table {
  width: 100%;
  height: 100%;
}
.change-div table th {
  width: 100px;
  padding: 0%;
  /* background-color: #fff; */
}
.change-div table td {
  position: relative;
  overflow: hidden;
}

.change-div table td .card-title {
  position: absolute;
  top: 8px;
  left: 2px;
  text-align: left;
}
.change-div table td .card-content {
  position: absolute;
  top: 45px;
  left: 8px;
  height: 40px;
  text-align: left;
  overflow: auto;
}

.image_circle {
  text-align: center;
  height: 100px;
  width: 100px;
  border-radius: 10%;
  background-position: 54% 36%;
  background-size: cover;
  margin: 0%;
}

.change-button {
  display: flex;
}
.btn-border-bottom {
  /* margin-right: 10px; */
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  color: #6b6b6b;
  font-size: 16px;
}
.change-button-01 {
  padding: 0% 15px 0% 15px;
  border-bottom: 1px solid #9b9b9b; /* 線の太さ 線の種類 線の色 */
}

.tab-wrap {
  margin-top: 5px;
  flex-wrap: wrap;
  /* overflow: hidden; */
  padding: 0 0 20px;
}

.tab-label {
  color: Gray;
  cursor: pointer;
  flex: 1;
  font-weight: bold;
  order: -1;
  padding: 12px 24px;
  position: relative;
  text-align: center;
  transition: cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  user-select: none;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
}

.tab-label:hover {
  background: rgba(255, 255, 255, 0.1);
}

.tab-switch:checked + .tab-label {
  color: #6b6b6b;
}

.tab-label::after {
  background: #6b6b6b;
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: translateX(100%);
  transition: cubic-bezier(0.4, 0, 0.2, 1) 0.2s 80ms;
  width: 100%;
  z-index: 1;
}

.tab-switch:checked ~ .tab-label::after {
  transform: translateX(-100%);
}

.tab-switch:checked + .tab-label::after {
  opacity: 1;
  transform: translateX(0);
}

.tab-content {
  height: 0;
  opacity: 0;
  padding: 0 20px;
  pointer-events: none;
  transform: translateX(-30%);
  transition: transform 0.3s 80ms, opacity 0.3s 80ms;
  width: 100%;
}

.tab-switch:checked ~ .tab-content {
  transform: translateX(30%);
}

.tab-switch:checked + .tab-label + .tab-content {
  height: auto;
  opacity: 1;
  order: 1;
  pointer-events: auto;
  transform: translateX(0);
}

.tab-wrap::after {
  content: "";
  height: 20px;
  order: -1;
  width: 100%;
}

.tab-switch {
  display: none;
}
.class-list-div {
  position: relative;
  top: 70px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  border-radius: 8% 8% 0% 0%;
  color: rgb(0, 0, 0);
}
.list-modal-page {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100vh;
  animation: fadeIn 0.5s ease 0.1s 1 normal backwards;
  background-color: #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(600px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.card-title {
  overflow: scroll;
  /* background-color: aquamarine; */
}
.card-title table {
  width: 98%;
  /* background-color: #6b6b6b; */
}
.card-title table th {
  width: 30%;
  /* background-color: #f3f3f3; */
  color: rgb(102, 102, 102);
  font-weight: 500;
}
.card-title table td {
  min-width: 120px;
  /* background-color: #f0f0f0; */
  /* border-bottom: 1px solid rgb(216, 216, 216); */
}
.show-class-info {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}
</style>
