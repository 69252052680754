<template>
  <div>
    <!-- Fitness_Class検索関連のヘッダーコンポネート -->
    <div class="heard-style">
      <!-- クラス登録画面 1-->
      <table class="heard-table" v-if="page === 1">
        <tr>
          <td class="back-icon" @click="doBackHome(1)">
            <img src="@/assets/class_header/close.svg" width="20" height="20" />
          </td>
          <td class="heard-title">クラス申請</td>
          <td class="heard-menu"></td>
        </tr>
      </table>

      <!-- クラス検索 2-->
      <table class="heard-table" v-if="page === 2">
        <tr>
          <td class="heard-title">クラス検索</td>
        </tr>
      </table>

      <!-- 拠点情報  3-->
      <table class="heard-table" v-if="page === 3">
        <tr>
          <td class="back-icon" @click="doBackList()">
            <img src="@/assets/class_header/close.svg" width="20" height="20" />
          </td>
          <td class="heard-title">認定コーチ情報</td>
          <td></td>
        </tr>
      </table>

      <!-- 4 -->
      <table class="heard-table" v-if="page === 4">
        <tr>
          <td class="back-icon" @click="doBackList()">
            <img src="@/assets/class_header/close.svg" width="20" height="20" />
          </td>
          <td class="heard-title">クラス情報</td>
          <td class="back-icon" @click="doCopyLink()">
            <img src="@/assets/logo/share.svg" width="25" height="25" />
          </td>
        </tr>
      </table>

      <!-- 5 -->
      <table class="heard-table" v-if="page === 5">
        <tr>
          <td class="back-icon" @click="$router.back()">
            <img src="@/assets/class_header/close.svg" width="20" height="20" />
          </td>
          <td class="heard-title">申し込み</td>
          <td></td>
        </tr>
      </table>

      <!-- 5 -->
      <table class="heard-table" v-if="page === 6">
        <tr>
          <td class="back-icon" @click="doBackList()">
            <img src="@/assets/class_header/close.svg" width="20" height="20" />
          </td>
          <td class="heard-title">{{ title }}</td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClassHeader",
  props: {
    page: String,
    title: String,
  },
  data() {
    return {};
  },
  methods: {
    // モーダル解放
    doBackHome(value) {
      if (value == 1) {
        this.$router.push({
          path: "/",
          props: true,
        });
      }
      this.$emit("do-back-home", value);
    },
    doBackList() {
      this.$emit("my-click", true);
    },
    doCopyLink() {
      this.$emit("copy-click", true);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heard-table {
  width: 100%;
  position: fixed;
  z-index: 3;
  margin-top: 0%;
  width: 100%;
  height: 58px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.back-icon {
  width: 20%;
}
.heard-title {
  text-align: center;
  padding-top: 5px;
  width: 60%;
  font-size: 20px;
  font-weight: 900;
  color: #6b6b6b;
}
.heard-menu {
  width: 20%;
}

.space-style-1 {
  height: 58px;
}

.slect-div {
  text-align: left;
  padding-left: 5%;
  padding-top: 5%;
}
.slect-style {
  width: 150px;
  height: 35px;
  font-size: 14px;
  background-color: #e2e2e2;
  border-radius: 5px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
}
.slect-style :active {
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
}
.slect-style :hover {
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
}
button {
  margin-top: 6px;
  width: 70px;
  height: 28px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  background-color: #189f00;
}
.url-share {
  margin-top: 6px;
  width: 70px;
  height: 28px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  color: #4b4a4a;
  font-weight: 600;
  text-align: center;
  background-color: #8b8a8a;
}
.content a {
  font-weight: bold;
}
.content a:hover {
  color: #f89174; /*文字色*/
  text-decoration: underline;
}
</style>
