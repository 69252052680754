<template>
  <div>
    <!-- <div class="heard-style">
      <a href="#">
        <img src="@/assets/home/yajirusi.svg" width="20" height="20" />
      </a>
      <h2>{{ programData.name }}</h2>
    </div> -->
    <!-- 画像 -->
    <div class="home home-style">
      <div class="home-img">
        <img :src="programData.image_url" loading="lazy" height="230" />
      </div>
    </div>

    <!-- 説明部分 -->
    <div v-if="programData.detail_list.length !== null">
      <div v-for="content in programData.detail_list" v-bind:key="content.id">
        <div
          v-if="
            (content.title !== null && content.title !== '') ||
              (content.explanation !== null && content.explanation !== '')
          "
        >
          <div class="card border-light mb-3 card-style">
            <div class="card-body">
              <h3 class="card-title title-font">{{ content.title }}</h3>
              <p class="card-text title-conten-font">
                {{ content.explanation }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="all-open">
      <div v-if="allEventValue">
        <button type="button" class="button-style" @click="allEvent(false)">
          All Close
        </button>
      </div>
      <div v-else-if="checkEventValue">
        <button type="button" class="button-style" @click="allEvent(false)">
          All Close
        </button>
      </div>
      <div v-else>
        <button type="button" class="button-style" @click="allEvent(true)">
          All Open
        </button>
      </div>
    </div>

    <!-- 詳細部分折り畳み -->
    <div class="check-div">
      <input
        id="acd-check1"
        class="acd-check"
        type="checkbox"
        v-model="check[0]"
      />
      <label class="acd-label" @click="checkEvent(0)">
        <h2 class="display-5 program-font-style">呼吸/アライメント改善</h2>
      </label>
      <div class="acd-content" v-if="check[0]">
        <Improvement />
      </div>
      <input
        id="acd-check2"
        class="acd-check"
        type="checkbox"
        v-model="check[1]"
      />
      <label class="acd-label" @click="checkEvent(1)">
        <h2 class="display-5 program-font-style">スタティックストレッチ</h2>
      </label>
      <div class="acd-content" v-if="check[1]">
        <StaticStrech />
      </div>
      <input
        id="acd-check3"
        class="acd-check"
        type="checkbox"
        v-model="check[2]"
      />
      <label class="acd-label" @click="checkEvent(2)">
        <h2 class="display-5 program-font-style">アクティベーション</h2>
      </label>
      <div class="acd-content" v-if="check[2]">
        <Activation />
      </div>
      <input
        id="acd-check4"
        class="acd-check"
        type="checkbox"
        v-model="check[3]"
      />
      <label class="acd-label" @click="checkEvent(3)">
        <h2 class="display-5 program-font-style">ダイナミックストレッチ</h2>
      </label>
      <div class="acd-content" v-if="check[3]">
        <DynamicMovement />
      </div>
      <input
        id="acd-check5"
        class="acd-check"
        type="checkbox"
        v-model="check[4]"
      />
      <label class="acd-label" @click="checkEvent(4)">
        <h2 class="display-5 program-font-style">ムーブメントトレーニング</h2>
      </label>
      <div class="acd-content" v-if="check[4]">
        <MovementTraining />
      </div>
      <input
        id="acd-check6"
        class="acd-check"
        type="checkbox"
        v-model="check[5]"
      />
      <label class="acd-label" @click="checkEvent(5)">
        <h2 class="display-5 program-font-style">サーキットトレーニング</h2>
      </label>
      <div class="acd-content" v-if="check[5]">
        <CircuitTraining />
      </div>
      <input
        id="acd-check7"
        class="acd-check"
        type="checkbox"
        v-model="check[6]"
      />
      <label class="acd-label" @click="checkEvent(6)">
        <h2 class="display-5 program-font-style">グループワーク</h2>
      </label>
      <div class="acd-content" v-if="check[6]">
        <GroupWorkAbout />
      </div>
    </div>
    <div class="sapace-style"></div>
  </div>
</template>

<script>
import Improvement from "@/components/programList/Improvement";
import StaticStrech from "@/components/programList/StaticStrech";
import Activation from "@/components/programList/Activation";
import DynamicMovement from "@/components/programList/DynamicMovement";
import MovementTraining from "@/components/programList/MovementTraining";
import CircuitTraining from "@/components/programList/CircuitTraining";
import GroupWorkAbout from "@/components/programList/GroupWork";
import firebase from "firebase/compat";

export default {
  name: "Basic",
  components: {
    Improvement,
    StaticStrech,
    Activation,
    DynamicMovement,
    MovementTraining,
    CircuitTraining,
    GroupWorkAbout,
  },
  created: function() {
    this.getProgramData();
  },
  data() {
    return {
      check: [false, false, false, false, false, false, false],
      allEventValue: false,
      checkEventValue: false,
      programData: {
        name: "",
        image_url: "",
        detail_list: [],
      },
    };
  },
  methods: {
    checkEvent(index) {
      if (!this.check[index]) {
        this.checkEventValue = true;
      } else {
        this.checkEventValue = false;
      }
      this.check[index] = !this.check[index];
    },
    allEvent(isValue) {
      if (!isValue) {
        this.checkEventValue = false;
      }
      let self = this;
      self.check.forEach(function(value, index) {
        self.check[index] = isValue;
      });
      self.allEventValue = isValue;
    },
    getProgramData() {
      if (localStorage.getItem("program_uid") != null) {
        var program_uid = localStorage.getItem("program_uid");
        let self = this;
        firebase
          .firestore()
          .collection(self.glb_table.program)
          .doc(program_uid)
          .get()
          .then((snap) => {
            sessionStorage.setItem("program_info", JSON.stringify(snap.data()));

            self.programData.name = snap.data().name;
            self.programData.image_url = snap.data().detail_image.image_url;
            self.programData.detail_list = snap.data().detail_content.detail_list;
          });
      }
    },
  },
};
</script>
<style scoped>
.heard-style {
  position: fixed;
  z-index: 2;
  margin-top: 0%;
  padding-top: 18px;
  width: 100%;
  height: 58px;
  background-color: #04545c;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.home-img img {
  margin-top: 40px;
  width: 100%;
  /* height: auto; */
}
.home-img img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
  white-space: pre-wrap;
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.all-open {
  text-align: right;
  margin-right: 2%;
  margin-top: 5%;
  margin-bottom: 5%;
}
.button-style {
  width: 60px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  padding: 0%;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #015a53;
}

/* 折りたたみ */
.check-div {
}
.acd-check {
  display: none;
  border-bottom: 1px solid rgb(177, 177, 177);
}
.acd-label {
  background: #04545c;
  color: rgb(255, 255, 255);
  display: block;
  margin-bottom: 1px;
  padding: 16px;
  position: relative;
  font-size: 2em;
  height: auto;
  border-bottom: 0.5px solid rgb(255, 254, 240);
  text-align: left;
  margin: 0%;
}
.acd-label:after {
  background: #04545c;
  box-sizing: border-box;
  content: url(../assets/home/downY.svg);
  display: block;
  font-family: "Font Awesome 5 Free";
  height: 50px;
  width: 50px;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0px;
  height: 55px;
  border-bottom: 0px solid rgb(255, 254, 240);
}
.acd-content {
  border: 1px solid #333;
  display: block;
  height: 0;
  opacity: 0;
  padding: 0 10px;
  transition: 0.5s;
  visibility: hidden;
  border: 0;
}
.acd-check:checked + .acd-label:after {
  content: url(../assets/home/upY.svg);
}
.acd-check:checked + .acd-label + .acd-content {
  height: auto;
  opacity: 1;
  padding: 10px;
  visibility: visible;
  border: 0;
  background-color: #ffffff;
  border-bottom: 0.5px solid rgb(255, 254, 240);
}
.program-font-style {
  font-weight: 600;
  width: 90%;
}
.sapace-style {
  height: 200px;
  background: #04545c;
  margin-bottom: 0%;
}
</style>
